import React, { useState, useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom';
// import { sha256 } from 'js-sha256';

// import uniqid from 'uniqid';
import Swal from 'sweetalert2'

import moment from 'moment-timezone';

import "../Pagina.css";
import "../content-styles.css"

import { getTokenInfo } from '../../../UseToken';

import * as Hosts from '../../../Hosts';
import {
    //set_language,
	reqGET,
	reqPOST,
	reqPUT,
    content_language,
    upgrade_cart,
    my_local_storage,
    repo_site_assets_link,
    // functionSendMail,

} from '../../../Utils';

import './Cart.css';

import Loader from '../../../loader'

import Navbar from "../Navbar/Navbar";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Footer from "../Footer/Footer";

import { language } from './Language';

// import {Mutex} from 'async-mutex';

const Cart = () => {

    // const mutex = new Mutex();

    const [, set_dados] = useState(null);

    const nota_de_encomenda = {
        id: '',
        info: {
            companycode: '',
            tipo_documento: 'nota_de_encomenda',
            origin: 'web',
            numero: '',
            serie: '',
            data: '',                                            // 'AAAA-MM-DD',
            timestamp: '',                                       // 'with all info',
            tem_aulas: false,
            id_usado_para_gerar_pagamento: '',
            pagamento_pendente: 0,
            metodo_de_pagamento: 0,
            documento: {
                rmb: {
                    rmb_ref: '',
                    rmb_ent: '',
                    rmb_pag: '',
                },
                user_info : {
                    id_user: '',
                    username: '',                                // para fazer match
                    name: '',
                    vat: '',
                    // vat: '999999990',                         // Caso não exista é o 999999990 que é o consumidor final genérico 
                    addresses: [{
                        village: '',
                        city: '',
                        type: [],
                        region: '',
                        country: '',
                        apartado: '',
                        localidade: '',
                        contats: [],
                        principal: '',
                        observation: '',
                        postal_code: '',
                        street_name: '',
                        address_detail: '',
                    }],                                          // user_data_inter
                    contacts: [{
                        email: '',
                        phone: '',
                        principal: 'true',
                        extensions: [],
                        observation: '',
                        mobile_phone: '',
                        building_number: '',
                        person_responsables: [{
                            name: '',
                            email: '',
                            extensions: '',
                            observation: '',
                            mobile_phone: ''
                        }],
                    }],                                          // user_data_inter
                    obs: '',
                },
                linhas:[
                    // {
                    //     id_produto: '',
                    //     designacao: '',
                    //     unidade: '',

                    //     quantidade: 0.00,
                    //     preco_unitario: 0.00000000, 
    
                    //     taxa_iva: 0.00 ,
                    //     taxa_desconto_linha: 0.00,
    
                    //     total_iliquido: 0.00,                                // preco_unitario * quantidade
    
                    //     total_desconto: 0.00,                                // total_iliquido * taxa_desconto_linha * 0.01
                    //     total_iliquido_com_desconto: 0.00,                   // total_iliquido - total_desconto
                        
                    //     total_iva: 0.00,                                     // total_iliquido_com_desconto * taxa_iva * 0.01
                    //     total_com_iva: 0.00,                                 // total_iliquido_com_desconto + total_iva
    
                    
                    //     total_liquido: 0.00,                                 // total_com_iva
                    // },
                ],
    
                // Valores do Globais do Documento
                total_iliquido_documento: 0.00,                                 // soma do total_iliquido_com_desconto de todas as linhas 
                total_desconto_linha_documento: 0.00,                           // soma do total_desconto de todas as linhas 
                taxa_desconto_global: 0,                                        // em %
                valor_desconto_global_a_aplicar: 0.00, 
                total_iliquido_documento_com_deconto_linha_e_global: 0.00,      // total_iliquido_documento - (total_desconto_linha_documento + valor_desconto_global_a_aplicar)

                total_iva_documento: 0.00,
                /* 
                let get_total_iva_documento = (linhas) => {
                    
                    let tmp_total_iva = 0.00
                    
                    foreach linha in linhas{
                        let v_tmp_iliq = linha.total_iliquido_com_desconto - linha.valor_desconto_global_a_aplicar 
                        let v_tmp_iva = v_tmp_iliq * linha.taxa_iva * 0.01
                        tmp_total_iva += v_tmp_iva
                    }

                    return tmp_total_iva
                }
                */

                total_documento: 0.00 //total_iliquido_documento_com_deconto_linha_e_global + total_iva_documento
            }
        }
    }

    // const checkoutInstance = startCheckout(manifest, [options])

    const dados = useRef(JSON.parse(JSON.stringify(nota_de_encomenda)));

    const shop_forfait = useRef([]);
    const shop_equipamento = useRef([]);
    const shop_aulas = useRef([]);
    const shop_pack = useRef([]);
    const shop_voucher = useRef([]);
    const shop_atividade = useRef([]);

	const [isloading, setLoading] = useState(true);
    const [isRedirect, setRedirect] = useState(false);

    const [lang, set_lang] = useState('pt');
    const [lang_id, set_lang_id] = useState('1');
    
    const [, set_equipments] = useState([]);
    const [, set_forfaits] = useState([]);
    const [, set_vouchers] = useState([]);
    const [, set_packs] = useState([]);
    const [, set_aulas] = useState([]);
    const [, set_atividades] = useState([]);

    const [lista_qr, /*set_lista_qr*/] = useState([]);

    const [cart, set_cart] = useState({});
    const [cart_final, set_cart_final] = useState([]);
    const [/*linhas_aux*/, set_linhas_aux] = useState([]);

    const [subtotal, set_subtotal] = useState(0.00);
    const [total_vat, set_total_vat] = useState([]);
    const [total, set_total] = useState(0.00);

    const [step, set_step] = useState('0');

    const [agree, setAgree] = useState(false);

    const [/*mbway_mobile_phone*/, set_mbway_mobile_phone] = useState('')

    const [refresh, setRefresh] = useState(0);

    const checkboxHandler = () => {
      // if agree === true, it will be set to false
      // if agree === false, it will be set to true
      setAgree(!agree);
      // Don't miss the exclamation mark
    }

    const checkboxHandler_reload = (agree) => {
        //console.log('AGREE', agree)
        if (agree === true)
            return true
        else 
            return false
        // if agree === false, it will be set to true
        //setAgree(!agree);
        // Don't miss the exclamation mark
    } 

    const get_data_func = () => {
        let data = moment().tz("Europe/Lisbon").format('YYYY-MM-DDTHH:mm:ss.SSSZ')
        return data.substring(0, data.length - 6) + 'Z'
    }

    /*
    const generate_qr = async (id_aux, aux_data, id_produto) => {
        var obj_aux = {
            tem_lista_entradas: true,
            tem_lista_equipamentos: true,
            tem_lista_aulas: true,
            qrcode: ''
        }

        // console.log('shop_forfait', shop_forfait.current)
        // console.log('shop_equipamento', shop_equipamento.current)
        // console.log('shop_voucher', shop_voucher.current)
        // console.log('shop_pack', shop_pack.current)
        // console.log('shop_atividade', shop_atividade.current)
        // console.log('shop_aulas', shop_aulas.current)

        const forfait_available = shop_forfait.current.filter(v => v.id === id_produto)
        const equipamento_available = shop_equipamento.current.filter(v => v.id === id_produto)
        const voucher_available = shop_voucher.current.filter(v => v.id === id_produto)
        const pack_available = shop_pack.current.filter(v => v.id === id_produto)
        const atividade_available = shop_atividade.current.filter(v => v.id === id_produto)
        const aula_available = shop_aulas.current.filter(v => v.id === id_produto)

        let objeto_completo = []

        if(forfait_available.length !== 0) {
            objeto_completo = forfait_available
        }
        else if(equipamento_available.length !== 0) {
            objeto_completo = equipamento_available
        }
        else if(voucher_available.length !== 0) {
            objeto_completo = voucher_available
        }
        else if(pack_available.length !== 0) {
            objeto_completo = pack_available
        }
        else if(atividade_available.length !== 0) {
            objeto_completo = atividade_available
        }
        else if(aula_available.length !== 0) {
            objeto_completo = aula_available
        }

        let lista_aux = []
        lista_aux = lista_qr

        // console.log('objeto_completo', objeto_completo)

        if(objeto_completo.length > 0) {
            for(let count = 1; count <= objeto_completo[0].info.detail.pessoas; count++) {

                //count_aux.current.push(count)
                //count_aux.current[count - 1] = count.toString()
                obj_aux = {}
                const equipamentos = objeto_completo[0].info.detail.equipamentos === false ? '0' : '1'
                // objeto_completo[0].info.detail.equipamentos === false ? obj_aux.tem_lista_equipamentos = false : obj_aux.tem_lista_equipamentos = true
                const code = aux_data + '*' + getTokenInfo().id + '*' + objeto_completo[0].info.detail.tipo + '*' + objeto_completo[0].info.detail.dias + '*' + objeto_completo[0].info.detail.pessoas + '*' + count.toString() + '*' + equipamentos + '*' + id_aux + '*';

                const sha_code = code + sha256(code)
                const final_code = code + sha256(sha_code)

                // var shallowCopy = _.clone(final_code);
                obj_aux.qrcode = final_code
                // console.log('####', obj_aux.qrcode)
                lista_aux.unshift(obj_aux)
                // console.log('--------><<<<<<<', lista_aux)

                //lista_qr.current.push(obj_aux)
            }
        }

        // console.log('-------->', lista_qr.current)
        console.log('-------->', lista_aux)
        //lista_qr.current = lista_aux
        set_lista_qr(lista_aux)
    }
    */

    useEffect(() => { 
        /*
        const gerer_qr = async (id_aux, aux_data, id_produto, qtd) => {
            var obj_aux = {
                tem_lista_entradas: true,
                tem_lista_equipamentos: true,
                tem_lista_aulas: true,
                qrcode: ''
            }
            const forfait_available = shop_forfait.current.filter(v => v.id === id_produto)
            const equipamento_available = shop_equipamento.current.filter(v => v.id === id_produto)
            const voucher_available = shop_voucher.current.filter(v => v.id === id_produto)
            const pack_available = shop_pack.current.filter(v => v.id === id_produto)
            const atividade_available = shop_atividade.current.filter(v => v.id === id_produto)
            const aula_available = shop_aulas.current.filter(v => v.id === id_produto)
            let objeto_completo = {}
            if (forfait_available.length !== 0) {
                objeto_completo = forfait_available
            }
            else if (equipamento_available.length !== 0) {
                objeto_completo = equipamento_available
            }
            if (voucher_available.length !== 0) {
                objeto_completo = voucher_available
            }
            if (pack_available.length !== 0) {
                objeto_completo = pack_available
            }
            if (atividade_available.length !== 0) {
                objeto_completo = atividade_available
            }
            if (aula_available.length !== 0) {
                objeto_completo = aula_available
            }
            //// A ALTERAÇAO É FEITA AQUI//////////////////////////////////////
            let lista_aux = []
            lista_aux = lista_qr
            let range = objeto_completo[0].info.detail.pessoas * qtd
            for (let count = 1; count <= range; count++) {
                //////////////////////////////////////////////////////////////////////
                obj_aux = {}
                const equipamentos = objeto_completo[0].info.detail.equipamentos === false ? '0' : '1'
                const code = aux_data + '*' + getTokenInfo().id + '*' + objeto_completo[0].info.detail.tipo + '*' + objeto_completo[0].info.detail.dias + '*' + objeto_completo[0].info.detail.pessoas + '*' + count.toString() + '*' + equipamentos + '*' + id_aux + '*';
                const sha_code = code + sha256(code)
                const final_code = code + sha256(sha_code)
                obj_aux.qrcode = final_code
                lista_aux.unshift(obj_aux)
            }
            lista_qr.current = lista_aux
        }
        */

        let clean_data = () => {

            set_cart({});
            set_cart_final([]);

            // DEEP COPY OF data_interface 
            set_dados(dados.current.info.companycode = Hosts.companycode)
            
            set_dados(dados.current.info.serie = '')
        }

        set_lang(localStorage.getItem('lang'))
        content_language.forEach(el => {
            if (el.code === localStorage.getItem('lang')) {
                set_lang_id(el.id)
            }
        })

        let getClientInfo = async () => {
            return await reqGET(`${Hosts.SIMCore_API}/entities/${Hosts.companycode}/${getTokenInfo().id}`)
                .then(res => {
                    // console.log(res.data)

                    set_dados(dados.current.info.documento.user_info.id_user = getTokenInfo().id)
                    set_dados(dados.current.info.documento.user_info.username = getTokenInfo().username)
                    set_dados(dados.current.info.documento.user_info.name = getTokenInfo().name)
                    //set_dados(dados.current.info.documento.user_info.vat = getTokenInfo().iat)
                    set_dados(dados.current.info.documento.user_info.vat = res.data[0].info.vat)
                    set_dados(dados.current.info.documento.user_info.contacts[0].email = res.data[0].contacts[0].email)
                    set_dados(dados.current.info.documento.user_info.contacts[0].mobile_phone = res.data[0].contacts[0].mobile_phone)
                    set_dados(dados.current.info.documento.user_info.addresses[0].address_detail = res.data[0].addresses[0].address_detail)
                    set_dados(dados.current.info.documento.user_info.addresses[0].localidade = res.data[0].addresses[0].localidade)
                    set_dados(dados.current.info.documento.user_info.addresses[0].postal_code = res.data[0].addresses[0].postal_code)
                    set_dados(dados.current.info.documento.user_info.addresses[0].country = res.data[0].addresses[0].country)

                })
                .catch(erro => console.log(erro)) 
        }
        if(getTokenInfo() !== undefined && getTokenInfo() !== null && getTokenInfo().profile === 'Cliente') {
            getClientInfo()
        }

        let getForfaits = async () => {
            return await reqGET(`${Hosts.SIMCore_API}/estancia/forfait/${Hosts.companycode}`)
                .then(res => {
    
                    set_dados(shop_forfait.current = res.data)
                    set_forfaits(res.data)
                    return res.data
                    // console.log(res.data)
                })
                .catch(erro => console.log(erro)) 
        }
    
        let getEquipamentos = async () => {
           return await  reqGET(`${Hosts.SIMCore_API}/estancia/equipamento/${Hosts.companycode}`)
                .then(res => { 

                    set_dados(shop_equipamento.current = res.data)
                    set_equipments(res.data)
                    return res.data
                    // console.log(res.data)
                })
                .catch(erro => console.log(erro)) 
        }

        let getVouchers = async () => {
            return await  reqGET(`${Hosts.SIMCore_API}/estancia/voucher/${Hosts.companycode}`)
                .then(res => { 

                    set_dados(shop_voucher.current = res.data)
                    set_vouchers(res.data)
                    return res.data
                    // console.log(res.data)
                 })
                 .catch(erro => console.log(erro)) 
        }

        let getPacks = async () => {
            return await  reqGET(`${Hosts.SIMCore_API}/estancia/pack/${Hosts.companycode}`)
                .then(res => { 

                    // console.log(res.data)
                    set_dados(shop_pack.current = res.data)
                    set_packs(res.data)
                    return res.data

                 })
                 .catch(erro => console.log(erro)) 
        }

        let getAulas = async () => {
            return await  reqGET(`${Hosts.SIMCore_API}/estancia/aula/info/${Hosts.companycode}`)
                .then(res => { 


                    set_dados(shop_aulas.current = res.data)
                    set_aulas(res.data)
                    return res.data
                    // console.log(res.data)
                })
                .catch(erro => console.log(erro)) 
        }

        let getAtividades = async () => {
            return await  reqGET(`${Hosts.SIMCore_API}/estancia/activity/${Hosts.companycode}`)
                .then(res => { 
      
                    set_dados(shop_atividade.current = res.data)
                    set_atividades(res.data)
                    return res.data
                    // console.log(res.data)
                })
                .catch(erro => console.log(erro)) 
        }

		clean_data()

        if(my_local_storage.getItem('snowCart') !== undefined && my_local_storage.getItem('snowCart') !== null) {
            console.log('cart', my_local_storage.getItem('snowCart'))
            
            let start =  new Date(JSON.parse(my_local_storage.getItem('snowCart')).date).getTime()
            let end =  new Date().getTime()

            var msDiff = start - end; 
            //var diffdays = Math.floor(msDiff / (1000 * 60 * 60 * 24));
            var diffhours = Math.floor(msDiff / (1000 * 60 * 60));
            if(diffhours > -2) {
                set_cart(JSON.parse(my_local_storage.getItem('snowCart')))
            }
            else {
                // setRedirect(true);
                set_cart({})
                my_local_storage.removeItem('snowCart')
            }
        }
        else {
            // setRedirect(true);
        }

        /*if(localStorage.getItem('lang') === undefined  || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if(el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }*/


        getPacks()
            .then(async (res_p) => {
                set_packs(res_p)

                await getVouchers()
                    .then(async (res_v) => {
                        set_vouchers(res_v)

                        await getAulas()
                            .then(async (res_a) => {
                                set_aulas(res_a)

                                await getForfaits()
                                    .then(async (res_f) => {
                                        set_forfaits(res_f)

                                        await getEquipamentos()
                                            .then(async (res_e) => {
                                                set_equipments(res_e)

                                                await getAtividades()
                                                    .then(async (res_at) => {
                                                        set_atividades(res_at)

                                                            let N_ENC = []

                                                            let total_aux = 0.00
                                                            let subtotal_aux = 0.00
                                                            let total_vat_aux = []
                                                            let cart_aux = {}
                                                            let cart_final_aux = []

                                                            let total_iliquido_documento = 0.00
                                                            let total_desconto_linha_documento = 0.00
                                                            let taxa_desconto_global = 0.00
                                                            let valor_desconto_global_a_aplicar = 0.00
                                                            let total_iliquido_documento_com_deconto_linha_e_global = 0.00

                                                            // let total_iva_documento = 0.00
                                                            let total_documento = 0.00

                                                            let tem_aulas = false

                                                            if(my_local_storage.getItem('snowCart') !== undefined && my_local_storage.getItem('snowCart') !== null) {
                                                                console.log('cart', my_local_storage.getItem('snowCart'))
                                                                
                                                                let start =  new Date(JSON.parse(my_local_storage.getItem('snowCart')).date).getTime()
                                                                let end =  new Date().getTime()
                                                    
                                                                var msDiff = start - end; 
                                                                //var diffdays = Math.floor(msDiff / (1000 * 60 * 60 * 24));
                                                                var diffhours = Math.floor(msDiff / (1000 * 60 * 60));
                                                                if(diffhours > -2) {
                                                                    cart_aux = JSON.parse(my_local_storage.getItem('snowCart'))

                                                                    set_cart(cart_aux)
                                                                }
                                                                else {
                                                                    // setRedirect(true);
                                                                }
                                                            }
                                                            
                                                            if(cart_aux['packs'] !== undefined && cart_aux['packs'].length > 0) {
                                                                cart_aux['packs'].forEach((el) => {
                                                                    res_p.forEach((el_p) => {
                                                                        if(el_p.id === el.id && parseInt(el.quantity) > 0) {

                                                                            // gerer_qr(dados.current.info['id_usado_para_gerar_pagamento'], dados.current.info.data, el.id, el.quantity) 

                                                                            if(el_p.info.forfait !== undefined) {
                                                                                if(total_vat_aux.length === 0) {
                                                                                    res_f.forEach((f_aux) => {
                                                                                        if(f_aux.id === el_p.info.forfait.id) {

                                                                                            if(el_p.info.forfait.category === 'Família') {
                                                                                                total_aux += parseFloat(f_aux.info.forfait.price) * parseInt(el.quantity)
                                                                                                total_aux += parseFloat(f_aux.info.pista.price) * parseInt(el.quantity)

                                                                                                total_vat_aux.push({id: f_aux.info.forfait.taxe, value: ((parseFloat(f_aux.info.forfait.price) - parseFloat(f_aux.info.forfait.price_s_vat)) * parseInt(el.quantity)).toFixed(3)})
                                                                                                total_vat_aux.push({id: f_aux.info.pista.taxe, value: ((parseFloat(f_aux.info.pista.price) - parseFloat(f_aux.info.pista.price_s_vat)) * parseInt(el.quantity)).toFixed(3)})
                                                                                            }
                                                                                            else {
                                                                                                total_aux += parseFloat(f_aux.info.forfait.price) * parseInt(el_p.info.detail.pessoas) * parseInt(el.quantity)
                                                                                                total_aux += parseFloat(f_aux.info.pista.price) * parseInt(el_p.info.detail.pessoas) * parseInt(el.quantity)

                                                                                                total_vat_aux.push({id: f_aux.info.forfait.taxe, value: ((parseFloat(f_aux.info.forfait.price) - parseFloat(f_aux.info.forfait.price_s_vat)) * parseInt(el.quantity)).toFixed(3)})
                                                                                                total_vat_aux.push({id: f_aux.info.pista.taxe, value: ((parseFloat(f_aux.info.pista.price) - parseFloat(f_aux.info.pista.price_s_vat)) * parseInt(el.quantity)).toFixed(3)})
                                                                                            }
                                                                                        }
                                                                                    })
                                                                                }
                                                                                else {
                                                                                    total_vat_aux.forEach(el_t => {
                                                                                        res_f.forEach((f_aux) => {
                                                                                            if(f_aux.id === el_p.info.forfait.id) {
                                                                                                if(el_t.id === f_aux.info.forfait.taxe) {
                                                                                                    if(el_p.info.forfait.category === 'Família') {
                                                                                                        total_aux += parseFloat(f_aux.info.forfait.price) * parseInt(el.quantity)

                                                                                                        el_t.value = (parseFloat(el_t.value) + ((parseFloat(f_aux.info.forfait.price) - parseFloat(f_aux.info.forfait.price_s_vat)) * parseInt(el.quantity))).toFixed(3)
                                                                                                    }
                                                                                                    else {
                                                                                                        if(el_p.info.forfait.category === 'Família') {
                                                                                                            total_aux += parseFloat(f_aux.info.forfait.price) * parseInt(el.quantity)

                                                                                                            el_t.value = (parseFloat(el_t.value) + ((parseFloat(f_aux.info.forfait.price) - parseFloat(f_aux.info.forfait.price_s_vat)) * parseInt(el.quantity))).toFixed(3)
                                                                                                        }
                                                                                                        else {
                                                                                                            total_aux += parseFloat(f_aux.info.forfait.price) * parseInt(el_p.info.detail.pessoas) * parseInt(el.quantity)

                                                                                                            el_t.value = (parseFloat(el_t.value) + ((parseFloat(f_aux.info.forfait.price) - parseFloat(f_aux.info.forfait.price_s_vat)) * parseInt(el.quantity))).toFixed(3)
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                                if(el_t.id === f_aux.info.pista.taxe) {
                                                                                                    if(el_p.info.forfait.category === 'Família') {
                                                                                                        total_aux += parseFloat(f_aux.info.pista.price) * parseInt(el.quantity)

                                                                                                        el_t.value = (parseFloat(el_t.value) + ((parseFloat(f_aux.info.pista.price) - parseFloat(f_aux.info.pista.price_s_vat)) * parseInt(el.quantity))).toFixed(3)
                                                                                                    }
                                                                                                    else {
                                                                                                        total_aux += parseFloat(f_aux.info.pista.price) * parseInt(el_p.info.detail.pessoas) * parseInt(el.quantity)

                                                                                                        el_t.value = (parseFloat(el_t.value) + ((parseFloat(f_aux.info.pista.price) - parseFloat(f_aux.info.pista.price_s_vat)) * parseInt(el.quantity))).toFixed(3)
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        })
                                                                                    })
                                                                                }

                                                                                res_f.forEach((f_aux) => {
                                                                                    if(f_aux.id === el_p.info.forfait.id) {

                                                                                        if(el_p.info.forfait.category === 'Família') {
                                                                                            cart_final_aux.push({
                                                                                                id: el.id,
                                                                                                name: (lang === 'pt' || el_p.info.language === undefined ? el_p.info.name : (el_p.info.language[lang_id] === undefined || el_p.info.language[lang_id] === null || el_p.info.language[lang_id].name === undefined || el_p.info.language[lang_id].name === '' ? el_p.info.name : el_p.info.language[lang_id].name)),
                                                                                                //name: el_p.info.name, 
                                                                                                category: (lang === 'pt' || el_p.info.language === undefined ? el_p.info.category : (el_p.info.language[lang_id] === undefined || el_p.info.language[lang_id] === null || el_p.info.language[lang_id].category === undefined || el_p.info.language[lang_id].category === '' ? el_p.info.category : el_p.info.language[lang_id].category)),
                                                                                                //category: el_p.info.category, 
                                                                                                observation: (parseInt(el_p.info.detail.pessoas) > 1 ? el_p.info.detail.pessoas + ' x ' : '') + (lang === 'pt' || f_aux.info.language === undefined ? f_aux.info.name : (f_aux.info.language[lang_id] === undefined || f_aux.info.language[lang_id] === null || f_aux.info.language[lang_id].name === undefined || f_aux.info.language[lang_id].name === '' ? f_aux.info.name : f_aux.info.language[lang_id].name)), 
                                                                                                unit_price: (parseFloat(f_aux.info.forfait.price_s_vat)).toFixed(3), 
                                                                                                unit_price_iva: (parseFloat(f_aux.info.forfait.price)).toFixed(2),
                                                                                                quantity: el.quantity, 
                                                                                                vat: f_aux.info.forfait.taxe, 
                                                                                                total: (parseFloat(f_aux.info.forfait.price) * parseInt(el.quantity)).toFixed(2)
                                                                                            })
                                                                                        }
                                                                                        else {
                                                                                            cart_final_aux.push({
                                                                                                id: el.id,
                                                                                                name: (lang === 'pt' || el_p.info.language === undefined ? el_p.info.name : (el_p.info.language[lang_id] === undefined || el_p.info.language[lang_id] === null || el_p.info.language[lang_id].name === undefined || el_p.info.language[lang_id].name === '' ? el_p.info.name : el_p.info.language[lang_id].name)),
                                                                                                //name: el_p.info.name , 
                                                                                                category: (lang === 'pt' || el_p.info.language === undefined ? el_p.info.category : (el_p.info.language[lang_id] === undefined || el_p.info.language[lang_id] === null || el_p.info.language[lang_id].category === undefined || el_p.info.language[lang_id].category === '' ? el_p.info.category : el_p.info.language[lang_id].category)),
                                                                                                //category: el_p.info.category, 
                                                                                                observation: (parseInt(el_p.info.detail.pessoas) > 1 ? el_p.info.detail.pessoas + ' x ' : '') + (lang === 'pt' || f_aux.info.language === undefined ? f_aux.info.name : (f_aux.info.language[lang_id] === undefined || f_aux.info.language[lang_id] === null || f_aux.info.language[lang_id].name === undefined || f_aux.info.language[lang_id].name === '' ? f_aux.info.name : f_aux.info.language[lang_id].name)), 
                                                                                                //observation: (parseInt(el_p.info.detail.pessoas) > 1 ? el_p.info.detail.pessoas + ' x ' : '') + f_aux.info.name, 
                                                                                                unit_price: (parseFloat(f_aux.info.forfait.price_s_vat)).toFixed(3), 
                                                                                                unit_price_iva: (parseFloat(f_aux.info.forfait.price)).toFixed(2),
                                                                                                quantity: el.quantity, 
                                                                                                vat: f_aux.info.forfait.taxe, 
                                                                                                total: (parseFloat(f_aux.info.forfait.price) * parseInt(el_p.info.detail.pessoas) * parseInt(el.quantity)).toFixed(2)
                                                                                            })
                                                                                        }
                                
                                                                                        // let linha_preco_unitario = (parseFloat(f_aux.info.forfait.price) - (parseFloat(f_aux.info.forfait.price) * parseFloat(f_aux.info.forfait.taxe) * 0.01)).toFixed(2)
                                                                                        // let linha_preco_unitario_com_iva = parseFloat(f_aux.info.forfait.price).toFixed(2)
                                                                                        // let linha_taxa_iva = f_aux.info.forfait.taxe
                                                                                        // let linha_total_iliquido = (parseFloat(linha_preco_unitario) * el.quantity).toFixed(2)
                                                                                        // let linha_total_liquido = (parseFloat(linha_preco_unitario_com_iva) * el.quantity).toFixed(2)
                                                                                        // let linha_total_desconto = (parseFloat(linha_total_iliquido) * parseFloat(0.00) * 0.01).toFixed(2)  // total_iliquido * taxa_desconto_linha * 0.01
                                                                                        // let linha_total_iliquido_desconto = (parseFloat(linha_total_iliquido) - parseFloat(linha_total_desconto)).toFixed(2)  // total_iliquido - total_desconto
                                                                                        // let linha_total_iva = (parseFloat(linha_total_liquido) * parseFloat(linha_taxa_iva) * 0.01).toFixed(2) // total_iliquido_com_desconto * taxa_iva * 0.01
                                                                                        // let linha_total_com_iva = (parseFloat(linha_total_iliquido_desconto) + parseFloat(linha_total_iva)).toFixed(2) // total_iliquido_com_desconto + total_iva
                                                                                        
                                                                                        let linha_preco_unitario = parseFloat(f_aux.info.forfait.price_s_vat) * parseInt(el_p.info.detail.pessoas)
                                                                                        if(el_p.info.forfait.category === 'Família') {
                                                                                            linha_preco_unitario = parseFloat(f_aux.info.forfait.price_s_vat)
                                                                                        }

                                                                                        let linha_taxa_iva = f_aux.info.forfait.taxe
                                                                                        // let linha_preco_unitario_com_iva = (parseFloat(f_aux.info.forfait.price_s_vat) * parseFloat(linha_taxa_iva) * 0.01)
                                                                                        let linha_total_iliquido = (parseFloat(linha_preco_unitario) * el.quantity)
                                                                                        // let linha_total_liquido = (parseFloat(linha_preco_unitario_com_iva) * el.quantity)
                                                                                        let linha_total_desconto = 0.00 // (parseFloat(linha_total_iliquido))  // total_iliquido * taxa_desconto_linha * 0.01
                                                                                        let linha_total_iliquido_desconto = (parseFloat(linha_total_iliquido) - parseFloat(linha_total_desconto))  // total_iliquido - total_desconto
                                                                                        let linha_total_iva = (parseFloat(linha_total_iliquido) * parseFloat(linha_taxa_iva) * 0.01) // total_iliquido_com_desconto * taxa_iva * 0.01
                                                                                        let linha_total_com_iva = (parseFloat(linha_total_iliquido_desconto) + parseFloat(linha_total_iva)) // total_iliquido_com_desconto + total_iva

                                                                                        let linha_total_iva_apresentado = (((parseFloat(f_aux.info.forfait.price) - parseFloat(f_aux.info.forfait.price_s_vat)) * parseInt(el_p.info.detail.pessoas) * parseInt(el.quantity))).toFixed(3)
                                                                                        if(el_p.info.forfait.category === 'Família') {
                                                                                            linha_total_iva_apresentado = (((parseFloat(f_aux.info.forfait.price) - parseFloat(f_aux.info.forfait.price_s_vat)) * parseInt(el.quantity))).toFixed(3)
                                                                                        }


                                                                                        let aux_filter = dados.current.info.documento.linhas.filter(el_filter => (el_filter.id_produto === f_aux.id && el_filter.pretence_ao_produto === el_p.id))
                                                                                        if(aux_filter.length > 0) {

                                                                                        }
                                                                                        else {
                                                                                            set_dados(dados.current.info.documento.linhas.push({
                                                                                                //id_produto: el_p.id,
                                                                                                //id_sub_produto: f_aux.id,
                                                                                                id_produto: f_aux.id,
                                                                                                pretence_ao_produto: el_p.id,

                                                                                                designacao: el_p.info.name,
                                                                                                subname: f_aux.info.name + ' - ' + f_aux.info.category,
                                                                                                observation: (parseInt(el_p.info.detail.pessoas) > 1 ? el_p.info.detail.pessoas + ' x ' : '') + f_aux.info.name, 
                                                                                                unidade: 'un',

                                                                                                aula_marcada: '',
                                                                                                // show_qr: true,
                                                                                                id_produto_na_lista_linhas: parseInt(dados.current.info.documento.linhas.length) + 1,

                                                                                                quantidade: el.quantity,
                                                                                                preco_unitario: linha_preco_unitario, 
                                            
                                                                                                taxa_iva: ((parseFloat(linha_taxa_iva).toFixed(2))).toString(),
                                                                                                taxa_desconto_linha: 0.00,
                                            
                                                                                                total_iliquido: linha_total_iliquido,
                                            
                                                                                                total_desconto: linha_total_desconto,
                                                                                                total_iliquido_com_desconto: linha_total_iliquido_desconto,
                                            
                                                                                                total_iva: linha_total_iva,
                                                                                                total_com_iva: linha_total_com_iva,
                                                                                                total_iva_apresentado:  parseFloat(linha_total_iva_apresentado).toFixed(2),
                                                                                                total_com_iva_apresentado: parseFloat(linha_total_com_iva).toFixed(2),
                                                                                                total_iliquido_apresentado: parseFloat(linha_total_iliquido).toFixed(2),

                                                                                                quantidade_aux_em_caso_de_aula: 0,

                                                                                                total_liquido: linha_total_com_iva
                                                                                            }))
                                                                                        }
                                            
                                                                                        if(el_p.info.forfait.category === 'Família') {
                                                                                            cart_final_aux.push({
                                                                                                id: el.id,
                                                                                                idp: '90',
                                                                                                pista: true,
                                                                                                name: (lang === 'pt' || el_p.info.language === undefined ? el_p.info.name : (el_p.info.language[lang_id] === undefined || el_p.info.language[lang_id] === null || el_p.info.language[lang_id].name === undefined || el_p.info.language[lang_id].name === '' ? el_p.info.name : el_p.info.language[lang_id].name)),
                                                                                                //name: el_p.info.name, 
                                                                                                category: (lang === 'pt' || el_p.info.language === undefined ? el_p.info.category : (el_p.info.language[lang_id] === undefined || el_p.info.language[lang_id] === null || el_p.info.language[lang_id].category === undefined || el_p.info.language[lang_id].category === '' ? el_p.info.category : el_p.info.language[lang_id].category)),
                                                                                                //category: el_p.info.category, 
                                                                                                observation: (parseInt(el_p.info.detail.pessoas) > 1 ? el_p.info.detail.pessoas + ' x ' : '') + language[lang].pista_aluguer, 
                                                                                                unit_price: (parseFloat(f_aux.info.pista.price_s_vat)).toFixed(3), 
                                                                                                unit_price_iva: (parseFloat(f_aux.info.pista.price)).toFixed(2),
                                                                                                quantity: el.quantity, 
                                                                                                vat: f_aux.info.pista.taxe, 
                                                                                                total: (parseFloat(f_aux.info.pista.price) * parseInt(el.quantity)).toFixed(2)
                                                                                            })
                                                                                        }
                                                                                        else {
                                                                                            cart_final_aux.push({
                                                                                                id: el.id,
                                                                                                idp: '90',
                                                                                                pista: true,
                                                                                                name: (lang === 'pt' || el_p.info.language === undefined ? el_p.info.name : (el_p.info.language[lang_id] === undefined || el_p.info.language[lang_id] === null || el_p.info.language[lang_id].name === undefined || el_p.info.language[lang_id].name === '' ? el_p.info.name : el_p.info.language[lang_id].name)),
                                                                                                //name: el_p.info.name, 
                                                                                                category: (lang === 'pt' || el_p.info.language === undefined ? el_p.info.category : (el_p.info.language[lang_id] === undefined || el_p.info.language[lang_id] === null || el_p.info.language[lang_id].category === undefined || el_p.info.language[lang_id].category === '' ? el_p.info.category : el_p.info.language[lang_id].category)),
                                                                                                //category: el_p.info.category, 
                                                                                                observation: (parseInt(el_p.info.detail.pessoas) > 1 ? el_p.info.detail.pessoas + ' x ' : '') + language[lang].pista_aluguer, 
                                                                                                unit_price: (parseFloat(f_aux.info.pista.price_s_vat)).toFixed(3), 
                                                                                                unit_price_iva: (parseFloat(f_aux.info.pista.price)).toFixed(2),
                                                                                                quantity: el.quantity, 
                                                                                                vat: f_aux.info.pista.taxe, 
                                                                                                total: (parseFloat(f_aux.info.pista.price) * parseInt(el_p.info.detail.pessoas) * parseInt(el.quantity)).toFixed(2)
                                                                                            })
                                                                                        }
                                
                                                                                        let linha_preco_unitario_2 = (parseFloat(f_aux.info.pista.price_s_vat) * parseInt(el_p.info.detail.pessoas))
                                                                                        if(el_p.info.forfait.category === 'Família') {
                                                                                            linha_preco_unitario_2 = (parseFloat(f_aux.info.pista.price_s_vat))
                                                                                        }
                                                                                        
                                                                                        let linha_taxa_iva_2 = f_aux.info.pista.taxe
                                                                                        // let linha_preco_unitario_com_iva_2 = (parseFloat(f_aux.info.pista.price_s_vat) * parseFloat(linha_taxa_iva_2) * 0.01)
                                                                                        let linha_total_iliquido_2 = (parseFloat(linha_preco_unitario_2) * el.quantity)
                                                                                        // let linha_total_liquido_2 = (parseFloat(linha_preco_unitario_com_iva_2) * el.quantity)
                                                                                        let linha_total_desconto_2 = 0.00 // (parseFloat(linha_total_iliquido_2))  // total_iliquido * taxa_desconto_linha * 0.01
                                                                                        let linha_total_iliquido_desconto_2 = (parseFloat(linha_total_iliquido_2) - parseFloat(linha_total_desconto_2))  // total_iliquido - total_desconto
                                                                                        let linha_total_iva_2 = (parseFloat(linha_total_iliquido_2) * parseFloat(linha_taxa_iva_2) * 0.01) // total_iliquido_com_desconto * taxa_iva * 0.01
                                                                                        let linha_total_com_iva_2 = (parseFloat(linha_total_iliquido_desconto_2) + parseFloat(linha_total_iva_2)) // total_iliquido_com_desconto + total_iva
                                                                                        
                                                                                        let linha_total_iva_apresentado_2 = (((parseFloat(f_aux.info.pista.price) - parseFloat(f_aux.info.pista.price_s_vat)) * parseInt(el_p.info.detail.pessoas) * parseInt(el.quantity))).toFixed(3)
                                                                                        if(el_p.info.forfait.category === 'Família') {
                                                                                            linha_total_iva_apresentado_2 = (((parseFloat(f_aux.info.pista.price) - parseFloat(f_aux.info.pista.price_s_vat)) * parseInt(el.quantity))).toFixed(3)
                                                                                        }


                                                                                        let aux_filter_2 = dados.current.info.documento.linhas.filter(el_filter => el_filter.id_produto === f_aux.info.pista.id && (el_filter.pretence_ao_produto === el_p.id) /*&& (el_filter.id_produto !== '90' )*/)
                                                                                        if(aux_filter_2.length > 0) {

                                                                                        }
                                                                                        else {
                                                                                            set_dados(dados.current.info.documento.linhas.push({
                                                                                                // id_produto: el_p.id,
                                                                                                // id_sub_produto: f_aux.info.pista.id,
                                                                                                id_produto: f_aux.info.pista.id,
                                                                                                pretence_ao_produto: el_p.id,

                                                                                                designacao: el_p.info.name,
                                                                                                subname: language[lang].pista_aluguer,
                                                                                                observation: (parseInt(el_p.info.detail.pessoas) > 1 ? el_p.info.detail.pessoas + ' x ' : '') + language[lang].pista_aluguer, 
                                                                                                unidade: 'un',
                                            
                                                                                                aula_marcada: '',
                                                                                                // show_qr: true,
                                                                                                id_produto_na_lista_linhas: parseInt(dados.current.info.documento.linhas.length) + 1,

                                                                                                quantidade: el.quantity,
                                                                                                preco_unitario: linha_preco_unitario_2, 
                                            
                                                                                                taxa_iva: ((parseFloat(linha_taxa_iva_2).toFixed(2))).toString(),
                                                                                                taxa_desconto_linha: 0.00,
                                            
                                                                                                total_iliquido: linha_total_iliquido_2,
                                            
                                                                                                total_desconto: linha_total_desconto_2,
                                                                                                total_iliquido_com_desconto: linha_total_iliquido_desconto_2,
                                            
                                                                                                total_iva: linha_total_iva_2,
                                                                                                total_com_iva: linha_total_com_iva_2,
                                                                                                total_iva_apresentado:  parseFloat(linha_total_iva_apresentado_2).toFixed(2),
                                                                                                total_com_iva_apresentado: parseFloat(linha_total_com_iva_2).toFixed(2),
                                                                                                total_iliquido_apresentado: parseFloat(linha_total_iliquido_2).toFixed(2),
                                                                                            
                                                                                                quantidade_aux_em_caso_de_aula: 0,
                                            
                                                                                                total_liquido: linha_total_com_iva_2
                                                                                            }))
                                                                                        }
                                        
                                                                                        total_iliquido_documento += parseFloat(linha_total_iliquido_desconto) + parseFloat(linha_total_iliquido_desconto_2)
                                                                                        total_desconto_linha_documento += parseFloat(linha_total_desconto) +  parseFloat(linha_total_desconto_2)
                                                                                    }
                                                                                })
                                                                            }

                                                                            if(el_p.info.equipamento !== undefined) {
                                                                                if(total_vat_aux.length === 0) {
                                                                                    res_e.forEach((e_aux) => {
                                                                                        if(e_aux.id === el_p.info.equipamento.id) {
                                                                                            total_aux += parseFloat(e_aux.info.value.price) * parseInt(el_p.info.detail.pessoas) * parseInt(el.quantity)

                                                                                            total_vat_aux.push({id: e_aux.info.value.taxe, value: (((parseFloat(e_aux.info.value.price) - parseFloat(e_aux.info.value.price_s_vat)) * parseInt(el_p.info.detail.pessoas)) * parseInt(el.quantity)).toFixed(3)})
                                                                                        }
                                                                                    })
                                                                                }
                                                                                else {
                                                                                    total_vat_aux.forEach(el_t => {
                                                                                        res_e.forEach((e_aux) => {
                                                                                            if(e_aux.id === el_p.info.equipamento.id) {
                                                                                                if(el_t.id === e_aux.info.value.taxe) {
                                                                                                    total_aux += parseFloat(e_aux.info.value.price) * parseInt(el_p.info.detail.pessoas) * parseInt(el.quantity)

                                                                                                    el_t.value = (parseFloat(el_t.value) + (((parseFloat(e_aux.info.value.price) - parseFloat(e_aux.info.value.price_s_vat)) * parseInt(el_p.info.detail.pessoas)) * parseInt(el.quantity))).toFixed(3)
                                                                                                }
                                                                                            }
                                                                                        })
                                                                                    })
                                                                                }

                                                                                res_e.forEach((e_aux) => {
                                                                                    if(e_aux.id === el_p.info.equipamento.id) {

                                                                                        cart_final_aux.push({
                                                                                            id: el.id,
                                                                                            name: (lang === 'pt' || el_p.info.language === undefined ? el_p.info.name : (el_p.info.language[lang_id] === undefined || el_p.info.language[lang_id] === null || el_p.info.language[lang_id].name === undefined || el_p.info.language[lang_id].name === '' ? el_p.info.name : el_p.info.language[lang_id].name)),
                                                                                            //name: el_p.info.name, 
                                                                                            category: (lang === 'pt' || el_p.info.language === undefined ? el_p.info.category : (el_p.info.language[lang_id] === undefined || el_p.info.language[lang_id] === null || el_p.info.language[lang_id].category === undefined || el_p.info.language[lang_id].category === '' ? el_p.info.category : el_p.info.language[lang_id].category)),
                                                                                            //category: el_p.info.category, 
                                                                                            observation: (parseInt(el_p.info.detail.pessoas) > 1 ? el_p.info.detail.pessoas + ' x ' : '') + (lang === 'pt' || e_aux.info.language === undefined ? e_aux.info.name : (e_aux.info.language[lang_id] === undefined || e_aux.info.language[lang_id] === null || e_aux.info.language[lang_id].name === undefined || e_aux.info.language[lang_id].name === '' ? e_aux.info.name : e_aux.info.language[lang_id].name)), 
                                                                                           // observation: (parseInt(el_p.info.detail.pessoas) > 1 ? el_p.info.detail.pessoas + ' x ' : '') + e_aux.info.name, 
                                                                                            unit_price: (parseFloat(e_aux.info.value.price_s_vat)).toFixed(3), 
                                                                                            unit_price_iva: (parseFloat(e_aux.info.value.price)).toFixed(2),
                                                                                            quantity: el.quantity, 
                                                                                            vat: e_aux.info.value.taxe, 
                                                                                            total: ((parseFloat(e_aux.info.value.price) * parseInt(el_p.info.detail.pessoas)) * parseInt(el.quantity)).toFixed(2)
                                                                                        })

                                                                                        let linha_preco_unitario = (parseFloat(e_aux.info.value.price_s_vat) * parseInt(el_p.info.detail.pessoas))
                                                                                        let linha_taxa_iva = e_aux.info.value.taxe
                                                                                        // let linha_preco_unitario_com_iva = (parseFloat(e_aux.info.value.price_s_vat) * parseFloat(linha_taxa_iva) * 0.01)
                                                                                        let linha_total_iliquido = ((parseFloat(linha_preco_unitario)) * el.quantity)
                                                                                        // let linha_total_liquido = ((parseFloat(linha_preco_unitario_com_iva) * parseInt(el_p.info.detail.pessoas)) * el.quantity)
                                                                                        let linha_total_desconto = 0.00 // (parseFloat(linha_total_iliquido))  // total_iliquido * taxa_desconto_linha * 0.01
                                                                                        let linha_total_iliquido_desconto = (parseFloat(linha_total_iliquido) - parseFloat(linha_total_desconto))  // total_iliquido - total_desconto
                                                                                        let linha_total_iva = (parseFloat(linha_total_iliquido) * parseFloat(linha_taxa_iva) * 0.01) // total_iliquido_com_desconto * taxa_iva * 0.01
                                                                                        let linha_total_com_iva = (parseFloat(linha_total_iliquido_desconto) + parseFloat(linha_total_iva)) // total_iliquido_com_desconto + total_iva
                                                                                        
                                                                                        let linha_total_iva_apresentado = (((parseFloat(e_aux.info.value.price) - parseFloat(e_aux.info.value.price_s_vat)) * parseInt(el_p.info.detail.pessoas) * parseInt(el.quantity))).toFixed(3)
                                                                                        
                                                                                        let aux_filter_3 = dados.current.info.documento.linhas.filter(el_filter => (el_filter.id_produto === e_aux.id && el_filter.pretence_ao_produto === el_p.id))
                                                                                        if(aux_filter_3.length > 0) {

                                                                                        }
                                                                                        else {
                                                                                            set_dados(dados.current.info.documento.linhas.push({
                                                                                                // id_produto: el_p.id,
                                                                                                // id_sub_produto: e_aux.id,
                                                                                                id_produto: e_aux.id,
                                                                                                pretence_ao_produto: el_p.id,

                                                                                                designacao: el_p.info.name,
                                                                                                subname: e_aux.info.name,
                                                                                                observation: (parseInt(el_p.info.detail.pessoas) > 1 ? el_p.info.detail.pessoas + ' x ' : '') + e_aux.info.name, 
                                                                                                unidade: 'un',
                                            
                                                                                                aula_marcada: '',
                                                                                                // show_qr: true,
                                                                                                id_produto_na_lista_linhas: parseInt(dados.current.info.documento.linhas.length) + 1,
                                            
                                                                                                quantidade: el.quantity,
                                                                                                preco_unitario: linha_preco_unitario, 
                                            
                                                                                                taxa_iva: ((parseFloat(linha_taxa_iva).toFixed(2))).toString(),
                                                                                                taxa_desconto_linha: 0.00,
                                            
                                                                                                total_iliquido: linha_total_iliquido,
                                            
                                                                                                total_desconto: linha_total_desconto,
                                                                                                total_iliquido_com_desconto: linha_total_iliquido_desconto,
                                            
                                                                                                total_iva: linha_total_iva,
                                                                                                total_com_iva: linha_total_com_iva,
                                                                                                total_iva_apresentado:  parseFloat(linha_total_iva_apresentado).toFixed(2),
                                                                                                total_com_iva_apresentado: parseFloat(linha_total_com_iva).toFixed(2),
                                                                                                total_iliquido_apresentado: parseFloat(linha_total_iliquido).toFixed(2),
                                                                                            
                                                                                                quantidade_aux_em_caso_de_aula: 0,
                                            
                                                                                                total_liquido: linha_total_com_iva
                                                                                            }))
                                                                                        }
                                        
                                                                                        total_iliquido_documento += parseFloat(linha_total_iliquido_desconto)
                                                                                        total_desconto_linha_documento += parseFloat(linha_total_desconto)

                                                                                    }
                                                                                })

                                                                            }

                                                                            if(el_p.info.aula !== undefined) {
                                                                                if(total_vat_aux.length === 0) {
                                                                                    res_a.forEach((a_aux) => {
                                                                                        if(a_aux.id === el_p.info.aula.id) {
                                                                                            total_aux += parseFloat(a_aux.info.value.price) * parseInt(el.quantity)

                                                                                            total_vat_aux.push({id: a_aux.info.value.taxe, value: ((parseFloat(a_aux.info.value.price) - parseFloat(a_aux.info.value.price_s_vat)) * parseInt(el.quantity)).toFixed(3)})
                                                                                        }
                                                                                    })
                                                                                }
                                                                                else {
                                                                                    total_vat_aux.forEach(el_t => {
                                                                                        res_a.forEach((a_aux) => {
                                                                                            if(a_aux.id === el_p.info.aula.id) {
                                                                                                if(el_t.id === a_aux.info.value.taxe) {
                                                                                                    total_aux += parseFloat(a_aux.info.value.price) * parseInt(el.quantity)

                                                                                                    el_t.value = (parseFloat(el_t.value) + ((parseFloat(a_aux.info.value.price) - parseFloat(a_aux.info.value.price_s_vat)) * parseInt(el.quantity))).toFixed(3)
                                                                                                }
                                                                                            }
                                                                                        })
                                                                                    })
                                                                                }

                                                                                res_a.forEach((a_aux) => {
                                                                                    if(a_aux.id === el_p.info.aula.id) {

                                                                                        cart_final_aux.push({
                                                                                            id: el.id,
                                                                                            name: (lang === 'pt' || el_p.info.language === undefined ? el_p.info.name : (el_p.info.language[lang_id] === undefined || el_p.info.language[lang_id] === null || el_p.info.language[lang_id].name === undefined || el_p.info.language[lang_id].name === '' ? el_p.info.name : el_p.info.language[lang_id].name)),
                                                                                            //name: el_p.info.name, 
                                                                                            category: (lang === 'pt' || el_p.info.language === undefined ? el_p.info.category : (el_p.info.language[lang_id] === undefined || el_p.info.language[lang_id] === null || el_p.info.language[lang_id].category === undefined || el_p.info.language[lang_id].category === '' ? el_p.info.category : el_p.info.language[lang_id].category)),
                                                                                            //category: el_p.info.category,
                                                                                            observation: (parseInt(el_p.info.detail.pessoas) > 1 ? el_p.info.detail.pessoas + ' x ' : '') + (lang === 'pt' || a_aux.info.language === undefined ? a_aux.info.name : (a_aux.info.language[lang_id] === undefined || a_aux.info.language[lang_id] === null || a_aux.info.language[lang_id].name === undefined || a_aux.info.language[lang_id].name === '' ? a_aux.info.name : a_aux.info.language[lang_id].name)), 
                                                                                            //observation: (parseInt(el_p.info.detail.pessoas) > 1 ? el_p.info.detail.pessoas + ' x ' : '') + a_aux.info.name, 
                                                                                            unit_price: (parseFloat(a_aux.info.value.price_s_vat)).toFixed(3), 
                                                                                            unit_price_iva: (parseFloat(a_aux.info.value.price)).toFixed(2),
                                                                                            quantity: el.quantity, 
                                                                                            vat: a_aux.info.value.taxe, 
                                                                                            total: (parseFloat(a_aux.info.value.price) * parseInt(el.quantity)).toFixed(2)
                                                                                        })
                                        
                                                                                        let linha_preco_unitario = (parseFloat(a_aux.info.value.price_s_vat))
                                                                                        let linha_taxa_iva = a_aux.info.value.taxe
                                                                                        // let linha_preco_unitario_com_iva = (parseFloat(a_aux.info.value.price_s_vat)* parseFloat(linha_taxa_iva) * 0.01)
                                                                                        let linha_total_iliquido = (parseFloat(linha_preco_unitario) * el.quantity)
                                                                                        // let linha_total_liquido = (parseFloat(linha_preco_unitario_com_iva) * el.quantity)
                                                                                        let linha_total_desconto = 0.00 // (parseFloat(linha_total_iliquido))  // total_iliquido * taxa_desconto_linha * 0.01
                                                                                        let linha_total_iliquido_desconto = (parseFloat(linha_total_iliquido) - parseFloat(linha_total_desconto))  // total_iliquido - total_desconto
                                                                                        let linha_total_iva = (parseFloat(linha_total_iliquido) * parseFloat(linha_taxa_iva) * 0.01) // total_iliquido_com_desconto * taxa_iva * 0.01
                                                                                        let linha_total_com_iva = (parseFloat(linha_total_iliquido_desconto) + parseFloat(linha_total_iva)) // total_iliquido_com_desconto + total_iva
                                                                                        
                                                                                        let linha_total_iva_apresentado = (((parseFloat(a_aux.info.value.price) - parseFloat(a_aux.info.value.price_s_vat)) * parseInt(el.quantity))).toFixed(3)


                                                                                        let aux_filter_4 = dados.current.info.documento.linhas.filter(el_filter => (el_filter.id_produto === a_aux.id && el_filter.pretence_ao_produto === el_p.id))
                                                                                        if(aux_filter_4.length > 0) {

                                                                                        }
                                                                                        else {
                                                                                            set_dados(dados.current.info.documento.linhas.push({
                                                                                                // id_produto: el_p.id,
                                                                                                // id_sub_produto: a_aux.id,
                                                                                                id_produto: a_aux.id,
                                                                                                pretence_ao_produto: el_p.id,

                                                                                                designacao: el_p.info.name,
                                                                                                subname: a_aux.info.name,
                                                                                                observation: (parseInt(el_p.info.detail.pessoas) > 1 ? el_p.info.detail.pessoas + ' x ' : '') + a_aux.info.name, 
                                                                                                unidade: 'un',
                                            
                                                                                                aula_marcada: '',
                                                                                                // show_qr: false,
                                                                                                id_produto_na_lista_linhas: parseInt(dados.current.info.documento.linhas.length) + 1,

                                                                                                //codigo_aula: '#' + uniqid().toUpperCase(),
                                            
                                                                                                quantidade: el.quantity,
                                                                                                preco_unitario: linha_preco_unitario, 
                                            
                                                                                                taxa_iva: ((parseFloat(linha_taxa_iva).toFixed(2))).toString(),
                                                                                                taxa_desconto_linha: 0.00,
                                            
                                                                                                total_iliquido: linha_total_iliquido,
                                            
                                                                                                total_desconto: linha_total_desconto,
                                                                                                total_iliquido_com_desconto: linha_total_iliquido_desconto,
                                            
                                                                                                total_iva: linha_total_iva,
                                                                                                total_com_iva: linha_total_com_iva,
                                                                                                total_iva_apresentado:  parseFloat(linha_total_iva_apresentado).toFixed(2),
                                                                                                total_com_iva_apresentado: parseFloat(linha_total_com_iva).toFixed(2),
                                                                                                total_iliquido_apresentado: parseFloat(linha_total_iliquido).toFixed(2),
                                                                                            
                                                                                                quantidade_aux_em_caso_de_aula: el.quantity,

                                                                                                total_liquido: linha_total_com_iva
                                                                                            }))
                                                                                        }
                                        
                                                                                        total_iliquido_documento += parseFloat(linha_total_iliquido_desconto)
                                                                                        total_desconto_linha_documento += parseFloat(linha_total_desconto)

                                                                                        tem_aulas = true

                                                                                    }
                                                                                })

                                                                            }

                                                                        }
                                                                    })
                                                                })
                                                            }

                                                            if(cart_aux['vouchers'] !== undefined && cart_aux['vouchers'].length > 0) {
                                                                cart_aux['vouchers'].forEach((el) => {
                                                                    res_v.forEach((el_v) => {
                                                                        if(el_v.id === el.id && parseInt(el.quantity) > 0) {

                                                                            // gerer_qr(dados.current.info['id_usado_para_gerar_pagamento'], dados.current.info.data, el.id, el.quantity) 

                                                                            if(el_v.info.forfait !== undefined) {
                                                                                if(total_vat_aux.length === 0) {
                                                                                    res_f.forEach((f_aux) => {
                                                                                        if(f_aux.id === el_v.info.forfait.id) {
                                                                                            total_aux += parseFloat(f_aux.info.forfait.price) * parseInt(el.quantity)
                                                                                            total_aux += parseFloat(f_aux.info.pista.price) * parseInt(el.quantity)

                                                                                            total_vat_aux.push({id: f_aux.info.forfait.taxe, value: ((parseFloat(f_aux.info.forfait.price) - parseFloat(f_aux.info.forfait.price_s_vat)) * parseInt(el.quantity)).toFixed(3)})
                                                                                            total_vat_aux.push({id: f_aux.info.pista.taxe, value: ((parseFloat(f_aux.info.pista.price) - parseFloat(f_aux.info.pista.price_s_vat)) * parseInt(el.quantity)).toFixed(3)})    
                                                                                        }
                                                                                    })
                                                                                }
                                                                                else {
                                                                                    total_vat_aux.forEach(el_t => {
                                                                                        res_f.forEach((f_aux) => {
                                                                                            if(f_aux.id === el_v.info.forfait.id) {
                                                                                                if(el_t.id === f_aux.info.forfait.taxe) {
                                                                                                    total_aux += parseFloat(f_aux.info.forfait.price) * parseInt(el.quantity)

                                                                                                    el_t.value = (parseFloat(el_t.value) + ((parseFloat(f_aux.info.forfait.price_s_vat) - parseFloat(f_aux.info.forfait.price_s_vat)) * parseInt(el.quantity))).toFixed(3)
                                                                                                }
                                                                                                if(el_t.id === f_aux.info.pista.taxe) {
                                                                                                    total_aux += parseFloat(f_aux.info.pista.price) * parseInt(el.quantity)

                                                                                                    el_t.value = (parseFloat(el_t.value) + ((parseFloat(f_aux.info.pista.price) - parseFloat(f_aux.info.pista.price_s_vat)) * parseInt(el.quantity))).toFixed(3)
                                                                                                }
                                                                                            }
                                                                                            else {
                                                                                                total_aux += parseFloat(el_t.info.detail.price)
        
                                                                                                total_vat_aux.push({id: el_t.info.taxe, value: ((parseFloat(el_t.info.detail.price) - parseFloat(el_t.info.detail.price_s_vat)) * parseInt(el.quantity)).toFixed(3)})
                                                                                            }
                                                                                        })
                                                                                    })
                                                                                }

                                                                                res_f.forEach((f_aux) => {
                                                                                    if(f_aux.id === el_v.info.forfait.id) {

                                                                                        cart_final_aux.push({
                                                                                            id: el.id,
                                                                                            name: (lang === 'pt' || el_v.info.language === undefined ? el_v.info.name : (el_v.info.language[lang_id] === undefined || el_v.info.language[lang_id] === null || el_v.info.language[lang_id].name === undefined || el_v.info.language[lang_id].name === '' ? el_v.info.name : el_v.info.language[lang_id].name)),
                                                                                            //name: el_v.info.name, 
                                                                                            category: (lang === 'pt' || el_v.info.language === undefined ? el_v.info.category : (el_v.info.language[lang_id] === undefined || el_v.info.language[lang_id] === null || el_v.info.language[lang_id].category === undefined || el_v.info.language[lang_id].category === '' ? el_v.info.category : el_v.info.language[lang_id].category)),
                                                                                            //category: el_v.info.category, 
                                                                                            observation: (parseInt(el_v.info.detail.pessoas) > 1 ? el_v.info.detail.pessoas + ' x ' : '') + (lang === 'pt' || f_aux.info.language === undefined ? f_aux.info.name : (f_aux.info.language[lang_id] === undefined || f_aux.info.language[lang_id] === null || f_aux.info.language[lang_id].name === undefined || f_aux.info.language[lang_id].name === '' ? f_aux.info.name : f_aux.info.language[lang_id].name)), 
                                                                                            //observation: (parseInt(el_v.info.detail.pessoas) > 1 ? el_v.info.detail.pessoas + ' x ' : '') + f_aux.info.name, 
                                                                                            unit_price: (parseFloat(f_aux.info.forfait.price_s_vat)).toFixed(3), 
                                                                                            unit_price_iva: (parseFloat(f_aux.info.forfait.price)).toFixed(2),
                                                                                            quantity: el.quantity, 
                                                                                            vat: f_aux.info.forfait.taxe, 
                                                                                            total: (parseFloat(f_aux.info.forfait.price) * parseInt(el.quantity)).toFixed(2)
                                                                                        })
                                
                                                                                        let linha_preco_unitario = (parseFloat(f_aux.info.forfait.price_s_vat))
                                                                                        let linha_taxa_iva = f_aux.info.forfait.taxe
                                                                                        // let linha_preco_unitario_com_iva = (parseFloat(f_aux.info.forfait.price_s_vat) * parseFloat(linha_taxa_iva) * 0.01)
                                                                                        let linha_total_iliquido = (parseFloat(linha_preco_unitario) * el.quantity)
                                                                                        // let linha_total_liquido = (parseFloat(linha_preco_unitario_com_iva) * el.quantity)
                                                                                        let linha_total_desconto = 0.00 // (parseFloat(linha_total_iliquido))  // total_iliquido * taxa_desconto_linha * 0.01
                                                                                        let linha_total_iliquido_desconto = (parseFloat(linha_total_iliquido) - parseFloat(linha_total_desconto))  // total_iliquido - total_desconto
                                                                                        let linha_total_iva = (parseFloat(linha_total_iliquido) * parseFloat(linha_taxa_iva) * 0.01) // total_iliquido_com_desconto * taxa_iva * 0.01
                                                                                        let linha_total_com_iva = (parseFloat(linha_total_iliquido_desconto) + parseFloat(linha_total_iva)) // total_iliquido_com_desconto + total_iva
                                                                                        
                                                                                        let linha_total_iva_apresentado = (((parseFloat(f_aux.info.forfait.price) - parseFloat(f_aux.info.forfait.price_s_vat)) * parseInt(el.quantity))).toFixed(3)

                                                                                        let aux_filter_5 = dados.current.info.documento.linhas.filter(el_filter => (el_filter.id_produto === f_aux.id && el_filter.pretence_ao_produto === el_v.id))
                                                                                        if(aux_filter_5.length > 0) {

                                                                                        }
                                                                                        else {
                                                                                            set_dados(dados.current.info.documento.linhas.push({
                                                                                                // id_produto: el_v.id,
                                                                                                // id_sub_produto: f_aux.id,
                                                                                                id_produto: f_aux.id,
                                                                                                pretence_ao_produto: el_v.id,

                                                                                                designacao: el_v.info.name,
                                                                                                subname: f_aux.info.name + ' - ' + f_aux.info.category,
                                                                                                observation: (parseInt(el_v.info.detail.pessoas) > 1 ? el_v.info.detail.pessoas + ' x ' : '') + f_aux.info.name, 
                                                                                                unidade: 'un',
                                            
                                                                                                aula_marcada: '',
                                                                                                // show_qr: true,
                                                                                                id_produto_na_lista_linhas: parseInt(dados.current.info.documento.linhas.length) + 1,
                                            
                                                                                                quantidade: el.quantity,
                                                                                                preco_unitario: linha_preco_unitario, 
                                            
                                                                                                taxa_iva: ((parseFloat(linha_taxa_iva).toFixed(2))).toString(),
                                                                                                taxa_desconto_linha: 0.00,
                                            
                                                                                                total_iliquido: linha_total_iliquido,
                                            
                                                                                                total_desconto: linha_total_desconto,
                                                                                                total_iliquido_com_desconto: linha_total_iliquido_desconto,
                                            
                                                                                                total_iva: linha_total_iva,
                                                                                                total_com_iva: linha_total_com_iva,
                                                                                                total_iva_apresentado:  parseFloat(linha_total_iva_apresentado).toFixed(2),
                                                                                                total_com_iva_apresentado: parseFloat(linha_total_com_iva).toFixed(2),
                                                                                                total_iliquido_apresentado: parseFloat(linha_total_iliquido).toFixed(2),
                                                                                            
                                                                                                quantidade_aux_em_caso_de_aula: 0,
                                            
                                                                                                total_liquido: linha_total_com_iva
                                                                                            }))
                                                                                        }
                                            
                                                                                        cart_final_aux.push({
                                                                                            id: el.id,
                                                                                            idp: '90',
                                                                                            pista: true,
                                                                                            name: (lang === 'pt' || el_v.info.language === undefined ? el_v.info.name : (el_v.info.language[lang_id] === undefined || el_v.info.language[lang_id] === null || el_v.info.language[lang_id].name === undefined || el_v.info.language[lang_id].name === '' ? el_v.info.name : el_v.info.language[lang_id].name)),
                                                                                            //name: el_v.info.name, 
                                                                                            category: (lang === 'pt' || el_v.info.language === undefined ? el_v.info.category : (el_v.info.language[lang_id] === undefined || el_v.info.language[lang_id] === null || el_v.info.language[lang_id].category === undefined || el_v.info.language[lang_id].category === '' ? el_v.info.category : el_v.info.language[lang_id].category)),
                                                                                            //category: el_v.info.category, 
                                                                                            observation: (parseInt(el_v.info.detail.pessoas) > 1 ? el_v.info.detail.pessoas + ' x ' : '') + language[lang].pista_aluguer, 
                                                                                            unit_price: (parseFloat(f_aux.info.pista.price_s_vat)).toFixed(3), 
                                                                                            unit_price_iva: (parseFloat(f_aux.info.pista.price)).toFixed(2),
                                                                                            quantity: el.quantity, 
                                                                                            vat: f_aux.info.pista.taxe, 
                                                                                            total: parseFloat(f_aux.info.pista.price) * parseInt(el.quantity).toFixed(2)
                                                                                        })
                                
                                                                                        let linha_preco_unitario_2 = (parseFloat(f_aux.info.pista.price_s_vat))
                                                                                        let linha_taxa_iva_2 = f_aux.info.pista.taxe
                                                                                        // let linha_preco_unitario_com_iva_2 = (parseFloat(f_aux.info.pista.price) * parseFloat(linha_taxa_iva_2) * 0.01)
                                                                                        let linha_total_iliquido_2 = (parseFloat(linha_preco_unitario_2) * el.quantity)
                                                                                        // let linha_total_liquido_2 = (parseFloat(linha_preco_unitario_com_iva_2) * el.quantity)
                                                                                        let linha_total_desconto_2 = 0.00 // (parseFloat(linha_total_iliquido_2))  // total_iliquido * taxa_desconto_linha * 0.01
                                                                                        let linha_total_iliquido_desconto_2 = (parseFloat(linha_total_iliquido_2) - parseFloat(linha_total_desconto_2))  // total_iliquido - total_desconto
                                                                                        let linha_total_iva_2 = (parseFloat(linha_total_iliquido_2) * parseFloat(linha_taxa_iva_2) * 0.01) // total_iliquido_com_desconto * taxa_iva * 0.01
                                                                                        let linha_total_com_iva_2 = (parseFloat(linha_total_iliquido_desconto_2) + parseFloat(linha_total_iva_2)) // total_iliquido_com_desconto + total_iva
                                                                                        
                                                                                        let linha_total_iva_apresentado_2 = (((parseFloat(f_aux.info.pista.price) - parseFloat(f_aux.info.pista.price_s_vat)) * parseInt(el.quantity))).toFixed(3)


                                                                                        let aux_filter_6 = dados.current.info.documento.linhas.filter(el_filter => el_filter.id_produto === f_aux.info.pista.id /*&& el_filter.id_produto !== '90'*/)
                                                                                        if(aux_filter_6.length > 0) {

                                                                                        }
                                                                                        else {
                                                                                            set_dados(dados.current.info.documento.linhas.push({
                                                                                                // id_produto: el_v.id,
                                                                                                // id_sub_produto: f_aux.info.pista.id,
                                                                                                id_produto: f_aux.info.pista.id,
                                                                                                pretence_ao_produto: el_v.id,

                                                                                                designacao: el_v.info.name,
                                                                                                subname: language[lang].pista_aluguer,
                                                                                                observation: (parseInt(el_v.info.detail.pessoas) > 1 ? el_v.info.detail.pessoas + ' x ' : '') + language[lang].pista_aluguer, 

                                                                                                unidade: 'un',
                                            
                                                                                                aula_marcada: '',
                                                                                                // show_qr: true,
                                                                                                id_produto_na_lista_linhas: parseInt(dados.current.info.documento.linhas.length) + 1,
                                            
                                                                                                quantidade: el.quantity,
                                                                                                preco_unitario: linha_preco_unitario_2, 
                                            
                                                                                                taxa_iva: ((parseFloat(linha_taxa_iva_2).toFixed(2))).toString(),
                                                                                                taxa_desconto_linha: 0.00,
                                            
                                                                                                total_iliquido: linha_total_iliquido_2,
                                            
                                                                                                total_desconto: linha_total_desconto_2,
                                                                                                total_iliquido_com_desconto: linha_total_iliquido_desconto_2,
                                            
                                                                                                total_iva: linha_total_iva_2,
                                                                                                total_com_iva: linha_total_com_iva_2,
                                                                                                total_iva_apresentado:  parseFloat(linha_total_iva_apresentado_2).toFixed(2),
                                                                                                total_com_iva_apresentado: parseFloat(linha_total_com_iva_2).toFixed(2),
                                                                                                total_iliquido_apresentado: parseFloat(linha_total_iliquido_2).toFixed(2),
                                                                                            
                                                                                                quantidade_aux_em_caso_de_aula: 0,
                                            
                                                                                                total_liquido: linha_total_com_iva_2
                                                                                            }))
                                                                                        }
                                        
                                                                                        total_iliquido_documento += parseFloat(linha_total_iliquido_desconto) + parseFloat(linha_total_iliquido_desconto_2)
                                                                                        total_desconto_linha_documento += parseFloat(linha_total_desconto) +  parseFloat(linha_total_desconto_2)
                                                                                    }
                                                                                })
                                                                            }

                                                                            if(el_v.info.equipamento !== undefined) {
                                                                                if(total_vat_aux.length === 0) {
                                                                                    res_e.forEach((e_aux) => {
                                                                                        if(e_aux.id === el_v.info.equipamento.id) {
                                                                                            total_aux += parseFloat(e_aux.info.value.price) * parseInt(el.quantity)

                                                                                            total_vat_aux.push({id: e_aux.info.value.taxe, value: (((parseFloat(e_aux.info.value.price) - parseFloat(e_aux.info.value.price_s_vat)) * parseInt(el_v.info.detail.pessoas)) * parseInt(el.quantity)).toFixed(3)})
                                                                                        }
                                                                                    })
                                                                                }
                                                                                else {
                                                                                    total_vat_aux.forEach(el_t => {
                                                                                        res_e.forEach((e_aux) => {
                                                                                            if(e_aux.id === el_v.info.equipamento.id) {
                                                                                                if(el_t.id === e_aux.info.value.taxe) {
                                                                                                    total_aux += parseFloat(e_aux.info.value.price) * parseInt(el.quantity)

                                                                                                    el_t.value = (parseFloat(el_t.value) + (((parseFloat(e_aux.info.value.price) - parseFloat(e_aux.info.value.price_s_vat)) * parseInt(el_v.info.detail.pessoas)) * parseInt(el.quantity))).toFixed(3)
                                                                                                }
                                                                                            }
                                                                                        })
                                                                                    })
                                                                                }

                                                                                res_e.forEach((e_aux) => {
                                                                                    if(e_aux.id === el_v.info.equipamento.id) {

                                                                                        cart_final_aux.push({
                                                                                            id: el.id,
                                                                                            name: (lang === 'pt' || el_v.info.language === undefined ? el_v.info.name : (el_v.info.language[lang_id] === undefined || el_v.info.language[lang_id] === null || el_v.info.language[lang_id].name === undefined || el_v.info.language[lang_id].name === '' ? el_v.info.name : el_v.info.language[lang_id].name)),
                                                                                            //name: el_v.info.name, 
                                                                                            category: (lang === 'pt' || el_v.info.language === undefined ? el_v.info.category : (el_v.info.language[lang_id] === undefined || el_v.info.language[lang_id] === null || el_v.info.language[lang_id].category === undefined || el_v.info.language[lang_id].category === '' ? el_v.info.category : el_v.info.language[lang_id].category)),
                                                                                            //category: el_v.info.category, 
                                                                                            observation: (parseInt(el_v.info.detail.pessoas) > 1 ? el_v.info.detail.pessoas + ' x ' : '') + (lang === 'pt' || e_aux.info.language === undefined ? e_aux.info.name : (e_aux.info.language[lang_id] === undefined || e_aux.info.language[lang_id] === null || e_aux.info.language[lang_id].name === undefined || e_aux.info.language[lang_id].name === '' ? e_aux.info.name : e_aux.info.language[lang_id].name)), 
                                                                                            //observation: (parseInt(el_v.info.detail.pessoas) > 1 ? el_v.info.detail.pessoas + ' x ' : '') + e_aux.info.name, 
                                                                                            unit_price: (parseFloat(e_aux.info.value.price_s_vat)).toFixed(3), 
                                                                                            unit_price_iva: (parseFloat(e_aux.info.value.price)).toFixed(2),
                                                                                            quantity: el.quantity, 
                                                                                            vat: e_aux.info.value.taxe, 
                                                                                            total: (parseFloat(e_aux.info.value.price) * parseInt(el_v.info.detail.pessoas)) * parseInt(el.quantity).toFixed(2)
                                                                                        })
                                        
                                                                                        let linha_preco_unitario = (parseFloat(e_aux.info.value.price_s_vat))
                                                                                        let linha_taxa_iva = e_aux.info.value.taxe
                                                                                        // let linha_preco_unitario_com_iva = (parseFloat(e_aux.info.value.price) * parseFloat(linha_taxa_iva) * 0.01)
                                                                                        let linha_total_iliquido = ((parseFloat(linha_preco_unitario) * parseInt(el_v.info.detail.pessoas)) * el.quantity)
                                                                                        // let linha_total_liquido = ((parseFloat(linha_preco_unitario_com_iva) * parseInt(el_v.info.detail.pessoas)) * el.quantity)
                                                                                        let linha_total_desconto = 0.00 // (parseFloat(linha_total_iliquido))  // total_iliquido * taxa_desconto_linha * 0.01
                                                                                        let linha_total_iliquido_desconto = (parseFloat(linha_total_iliquido) - parseFloat(linha_total_desconto))  // total_iliquido - total_desconto
                                                                                        let linha_total_iva = (parseFloat(linha_total_iliquido) * parseFloat(linha_taxa_iva) * 0.01) // total_iliquido_com_desconto * taxa_iva * 0.01
                                                                                        let linha_total_com_iva = (parseFloat(linha_total_iliquido_desconto) + parseFloat(linha_total_iva)) // total_iliquido_com_desconto + total_iva
                                                                                        
                                                                                        let linha_total_iva_apresentado = (((parseFloat(e_aux.info.value.price) - parseFloat(e_aux.info.value.price_s_vat)) * parseInt(el.quantity))).toFixed(3)

                                                                                        let aux_filter_7 = dados.current.info.documento.linhas.filter(el_filter => (el_filter.id_produto === e_aux.id && el_filter.pretence_ao_produto === el_v.id))
                                                                                        if(aux_filter_7.length > 0) {

                                                                                        }
                                                                                        else {
                                                                                            set_dados(dados.current.info.documento.linhas.push({
                                                                                                // id_produto: el_v.id,
                                                                                                // id_sub_produto: e_aux.id,
                                                                                                id_produto: e_aux.id,
                                                                                                pretence_ao_produto: el_v.id,

                                                                                                designacao: el_v.info.name,
                                                                                                subname: e_aux.info.name,
                                                                                                observation: (parseInt(el_v.info.detail.pessoas) > 1 ? el_v.info.detail.pessoas + ' x ' : '') + e_aux.info.name, 
                                                                                                unidade: 'un',
                                            
                                                                                                aula_marcada: '',
                                                                                                // show_qr: true,
                                                                                                id_produto_na_lista_linhas: parseInt(dados.current.info.documento.linhas.length) + 1,
                                            
                                                                                                quantidade: el.quantity,
                                                                                                preco_unitario: linha_preco_unitario, 
                                            
                                                                                                taxa_iva: ((parseFloat(linha_taxa_iva).toFixed(2))).toString(),
                                                                                                taxa_desconto_linha: 0.00,
                                            
                                                                                                total_iliquido: linha_total_iliquido,
                                            
                                                                                                total_desconto: linha_total_desconto,
                                                                                                total_iliquido_com_desconto: linha_total_iliquido_desconto,
                                            
                                                                                                total_iva: linha_total_iva,
                                                                                                total_com_iva: linha_total_com_iva,
                                                                                                total_iva_apresentado:  parseFloat(linha_total_iva_apresentado).toFixed(2),
                                                                                                total_com_iva_apresentado: parseFloat(linha_total_com_iva).toFixed(2),
                                                                                                total_iliquido_apresentado: parseFloat(linha_total_iliquido).toFixed(2),
                                                                                            
                                                                                                quantidade_aux_em_caso_de_aula: 0,
                                            
                                                                                                total_liquido: linha_total_com_iva
                                                                                            }))
                                                                                        }
                                        
                                                                                        total_iliquido_documento += parseFloat(linha_total_iliquido_desconto)
                                                                                        total_desconto_linha_documento += parseFloat(linha_total_desconto)

                                                                                    }
                                                                                })

                                                                            }

                                                                            if(el_v.info.aula !== undefined) {
                                                                                if(total_vat_aux.length === 0) {
                                                                                    res_a.forEach((a_aux) => {
                                                                                        if(a_aux.id === el_v.info.aula.id) {
                                                                                            total_aux += parseFloat(a_aux.info.value.price) * parseInt(el.quantity)

                                                                                            total_vat_aux.push({id: a_aux.info.value.taxe, value: ((parseFloat(a_aux.info.value.price)- parseFloat(a_aux.info.value.price_s_vat)) * parseInt(el.quantity)).toFixed(3)})
                                                                                        }
                                                                                    })
                                                                                }
                                                                                else {
                                                                                    total_vat_aux.forEach(el_t => {
                                                                                        res_a.forEach((a_aux) => {
                                                                                            if(a_aux.id === el_v.info.aula.id) {
                                                                                                // total_aux += parseFloat(a_aux.info.value.price) * parseInt(el.quantity)
                                                                                                total_aux += parseFloat(a_aux.info.value.price)

                                                                                                if(el_t.id === a_aux.info.value.taxe) {
                                                                                                    el_t.value = (parseFloat(el_t.value) + ((parseFloat(a_aux.info.value.price) - parseFloat(a_aux.info.value.price_s_vat)) * parseInt(el.quantity))).toFixed(3)
                                                                                                }
                                                                                                else {
                                                                                                    total_vat_aux.push({id: el_t.info.taxe, value: ((parseFloat(el_t.info.detail.price) - parseFloat(el_t.info.detail.price_s_vat)) * parseInt(el.quantity)).toFixed(3)})
                                                                                                }
                                                                                            }
                                                                                        })
                                                                                    })
                                                                                }

                                                                                res_a.forEach((a_aux) => {
                                                                                    if(a_aux.id === el_v.info.aula.id) {

                                                                                        cart_final_aux.push({
                                                                                            id: el.id,
                                                                                            name: (lang === 'pt' || el_v.info.language === undefined ? el_v.info.name : (el_v.info.language[lang_id] === undefined || el_v.info.language[lang_id] === null || el_v.info.language[lang_id].name === undefined || el_v.info.language[lang_id].name === '' ? el_v.info.name : el_v.info.language[lang_id].name)),
                                                                                            //name: el_v.info.name, 
                                                                                            category: (lang === 'pt' || el_v.info.language === undefined ? el_v.info.category : (el_v.info.language[lang_id] === undefined || el_v.info.language[lang_id] === null || el_v.info.language[lang_id].category === undefined || el_v.info.language[lang_id].category === '' ? el_v.info.category : el_v.info.language[lang_id].category)),
                                                                                            //category: el_v.info.category, 
                                                                                            observation: (parseInt(el_v.info.detail.pessoas) > 1 ? el_v.info.detail.pessoas + ' x ' : '') + (lang === 'pt' || a_aux.info.language === undefined ? a_aux.info.name : (a_aux.info.language[lang_id] === undefined || a_aux.info.language[lang_id] === null || a_aux.info.language[lang_id].name === undefined || a_aux.info.language[lang_id].name === '' ? a_aux.info.name : a_aux.info.language[lang_id].name)), 
                                                                                            //observation: (parseInt(el_v.info.detail.pessoas) > 1 ? el_v.info.detail.pessoas + ' x ' : '') + a_aux.info.name, 
                                                                                            unit_price: (parseFloat(a_aux.info.value.price_s_vat)).toFixed(3), 
                                                                                            unit_price_iva: (parseFloat(a_aux.info.value.price)).toFixed(2),
                                                                                            quantity: el.quantity, 
                                                                                            vat: a_aux.info.value.taxe, 
                                                                                            total: (parseFloat(a_aux.info.value.price) * parseInt(el.quantity)).toFixed(2)
                                                                                        })
                                        
                                                                                        let linha_preco_unitario = (parseFloat(a_aux.info.value.price_s_vat))
                                                                                        let linha_taxa_iva = a_aux.info.value.taxe
                                                                                        // let linha_preco_unitario_com_iva = (parseFloat(a_aux.info.value.price) * parseFloat(linha_taxa_iva) * 0.01)
                                                                                        let linha_total_iliquido = (parseFloat(linha_preco_unitario) * el.quantity)
                                                                                        // let linha_total_liquido = (parseFloat(linha_preco_unitario_com_iva) * el.quantity)
                                                                                        let linha_total_desconto = 0.00 // (parseFloat(linha_total_iliquido))  // total_iliquido * taxa_desconto_linha * 0.01
                                                                                        let linha_total_iliquido_desconto = (parseFloat(linha_total_iliquido) - parseFloat(linha_total_desconto))  // total_iliquido - total_desconto
                                                                                        let linha_total_iva = (parseFloat(linha_total_iliquido) * parseFloat(linha_taxa_iva) * 0.01) // total_iliquido_com_desconto * taxa_iva * 0.01
                                                                                        let linha_total_com_iva = (parseFloat(linha_total_iliquido_desconto) + parseFloat(linha_total_iva)) // total_iliquido_com_desconto + total_iva
                                                                                        
                                                                                        let linha_total_iva_apresentado = (((parseFloat(a_aux.info.value.price) - parseFloat(a_aux.info.value.price_s_vat)) * parseInt(el.quantity))).toFixed(3)
                                                                                        
                                                                                        let aux_filter_8 = dados.current.info.documento.linhas.filter(el_filter => (el_filter.id_produto === a_aux.id && el_filter.pretence_ao_produto === el_v.id))
                                                                                        if(aux_filter_8.length > 0) {

                                                                                        }
                                                                                        else {
                                                                                            set_dados(dados.current.info.documento.linhas.push({
                                                                                                // id_produto: el_v.id,
                                                                                                // id_sub_produto: a_aux.id,
                                                                                                id_produto: a_aux.id,
                                                                                                pretence_ao_produto: el_v.id,

                                                                                                designacao: el_v.info.name,
                                                                                                subname: a_aux.info.name,
                                                                                                observation: (parseInt(el_v.info.detail.pessoas) > 1 ?  el_v.info.detail.pessoas + ' x ' : '') + a_aux.info.name, 
                                                                                                unidade: 'un',
                                            
                                                                                                aula_marcada: '',
                                                                                                // show_qr: false,
                                                                                                id_produto_na_lista_linhas: parseInt(dados.current.info.documento.linhas.length) + 1,

                                                                                                // codigo_aula: '#' + uniqid().toUpperCase(),
                                            
                                                                                                quantidade: el.quantity,
                                                                                                preco_unitario: linha_preco_unitario, 
                                            
                                                                                                taxa_iva: ((parseFloat(linha_taxa_iva).toFixed(2))).toString(),
                                                                                                taxa_desconto_linha: 0.00,
                                            
                                                                                                total_iliquido: linha_total_iliquido,
                                            
                                                                                                total_desconto: linha_total_desconto,
                                                                                                total_iliquido_com_desconto: linha_total_iliquido_desconto,
                                            
                                                                                                total_iva: linha_total_iva,
                                                                                                total_com_iva: linha_total_com_iva,
                                                                                                total_iva_apresentado:  parseFloat(linha_total_iva_apresentado).toFixed(2),
                                                                                                total_com_iva_apresentado: parseFloat(linha_total_com_iva).toFixed(2),
                                                                                                total_iliquido_apresentado: parseFloat(linha_total_iliquido).toFixed(2),
                                                                                            
                                                                                                quantidade_aux_em_caso_de_aula: el.quantity,
                                            
                                                                                                total_liquido: linha_total_com_iva
                                                                                            }))
                                                                                        }
                                        
                                                                                        total_iliquido_documento += parseFloat(linha_total_iliquido_desconto)
                                                                                        total_desconto_linha_documento += parseFloat(linha_total_desconto)

                                                                                        tem_aulas = true

                                                                                    }
                                                                                })

                                                                            }

                                                                        }
                                                                    })
                                                                })
                                                            }

                                                            if(cart_aux['forfaits'] !== undefined && cart_aux['forfaits'].length > 0) {
                                                                cart_aux['forfaits'].forEach((el) => {
                                                                    res_f.forEach((el_f) => {
                                                                        if(el_f.id === el.id && parseInt(el.quantity) > 0) {

                                                                            // gerer_qr(dados.current.info['id_usado_para_gerar_pagamento'], dados.current.info.data, el.id, el.quantity) 

                                                                            if(total_vat_aux.length === 0) {
                                                                                total_aux += parseFloat(el_f.info.forfait.price) * parseInt(el.quantity)
                                                                                total_aux += parseFloat(el_f.info.pista.price) * parseInt(el.quantity)

                                                                                total_vat_aux.push({id: el_f.info.forfait.taxe, value: ((parseFloat(el_f.info.forfait.price) - parseFloat(el_f.info.forfait.price_s_vat)) * parseInt(el.quantity)).toFixed(3)})
                                                                                total_vat_aux.push({id: el_f.info.pista.taxe, value: ((parseFloat(el_f.info.pista.price) - parseFloat(el_f.info.pista.price_s_vat)) * parseInt(el.quantity)).toFixed(3)})
                                                                            }
                                                                            else {
                                                                                // total_aux += parseFloat(el_f.info.forfait.price) * parseInt(el.quantity)
                                                                                total_vat_aux.forEach(el_t => {
                                                                                    if(el_t.id === el_f.info.forfait.taxe) {
                                                                                        total_aux += parseFloat(el_f.info.forfait.price) * parseInt(el.quantity)

                                                                                        el_t.value = (parseFloat(el_t.value) + ((parseFloat(el_f.info.forfait.price) - parseFloat(el_f.info.forfait.price_s_vat)) * parseInt(el.quantity))).toFixed(3)
                                                                                    }
                                                                                    if(el_t.id === el_f.info.pista.taxe) {

                                                                                        total_aux += parseFloat(el_f.info.pista.price) * parseInt(el.quantity)
                                                                                        el_t.value = (parseFloat(el_t.value) + ((parseFloat(el_f.info.pista.price) - parseFloat(el_f.info.pista.price_s_vat)) * parseInt(el.quantity))).toFixed(3)
                                                                                    }
                                                                                })
                                                                            }

                                                                            cart_final_aux.push({
                                                                                id: el.id,
                                                                                name: (lang === 'pt' || el_f.info.language === undefined ? el_f.info.name : (el_f.info.language[lang_id] === undefined || el_f.info.language[lang_id] === null || el_f.info.language[lang_id].name === undefined || el_f.info.language[lang_id].name === '' ? el_f.info.name : el_f.info.language[lang_id].name)),
                                                                                //name: el_f.info.name, 
                                                                                category: (lang === 'pt' || el_f.info.language === undefined ? el_f.info.category : (el_f.info.language[lang_id] === undefined || el_f.info.language[lang_id] === null || el_f.info.language[lang_id].category === undefined || el_f.info.language[lang_id].category === '' ? el_f.info.category : el_f.info.language[lang_id].category)),
                                                                                //category: el_f.info.category, 
                                                                                observation: (lang === 'pt' || el_f.info.language === undefined ? el_f.info.observation : (el_f.info.language[lang_id] === undefined || el_f.info.language[lang_id] === null || el_f.info.language[lang_id].observation === undefined || el_f.info.language[lang_id].observation === '' ? el_f.info.observation : el_f.info.language[lang_id].observation)), 
                                                                                //observation: el_f.info.observation, 
                                                                                unit_price: (parseFloat(el_f.info.forfait.price_s_vat)).toFixed(3), 
                                                                                unit_price_iva: (parseFloat(el_f.info.forfait.price)).toFixed(2),
                                                                                quantity: el.quantity, 
                                                                                vat: el_f.info.forfait.taxe, 
                                                                                total: (parseFloat(el_f.info.forfait.price) * parseInt(el.quantity)).toFixed(2)
                                                                            })

                                                                            let linha_preco_unitario = (parseFloat(el_f.info.forfait.price_s_vat))
                                                                            let linha_taxa_iva = el_f.info.forfait.taxe
                                                                            // let linha_preco_unitario_com_iva = (parseFloat(el_f.info.forfait.price)* parseFloat(linha_taxa_iva) * 0.01)
                                                                            let linha_total_iliquido = (parseFloat(linha_preco_unitario) * el.quantity)
                                                                            // let linha_total_liquido = (parseFloat(linha_preco_unitario_com_iva) * el.quantity)
                                                                            let linha_total_desconto = 0.00 // (parseFloat(linha_total_iliquido))  // total_iliquido * taxa_desconto_linha * 0.01
                                                                            let linha_total_iliquido_desconto = (parseFloat(linha_total_iliquido) - parseFloat(linha_total_desconto))  // total_iliquido - total_desconto
                                                                            let linha_total_iva = (parseFloat(linha_total_iliquido) * parseFloat(linha_taxa_iva) * 0.01) // total_iliquido_com_desconto * taxa_iva * 0.01
                                                                            let linha_total_com_iva = (parseFloat(linha_total_iliquido_desconto) + parseFloat(linha_total_iva)) // total_iliquido_com_desconto + total_iva
                                                                            
                                                                            let linha_total_iva_apresentado = (((parseFloat(el_f.info.forfait.price) - parseFloat(el_f.info.forfait.price_s_vat)) * parseInt(el.quantity))).toFixed(3)


                                                                            let aux_filter_8 = dados.current.info.documento.linhas.filter(el_filter => (el_filter.id_produto === el_f.id && el_filter.pretence_ao_produto === el_f.id))
                                                                            if(aux_filter_8.length > 0) {

                                                                            }
                                                                            else {
                                                                                set_dados(dados.current.info.documento.linhas.push({
                                                                                    //id_produto: el_f.id,
                                                                                    id_produto: el_f.id,
                                                                                    pretence_ao_produto: el_f.id,

                                                                                    designacao: el_f.info.name + ' - ' + el_f.info.category,
                                                                                    subname: '',
                                                                                    observation: '', 
                                                                                    unidade: 'un',
                                            
                                                                                    aula_marcada: '',
                                                                                    // show_qr: true,
                                                                                    id_produto_na_lista_linhas: parseInt(dados.current.info.documento.linhas.length) + 1,

                                                                                    quantidade: el.quantity,
                                                                                    preco_unitario: linha_preco_unitario, 

                                                                                    taxa_iva: ((parseFloat(linha_taxa_iva).toFixed(2))).toString(),
                                                                                    taxa_desconto_linha: 0.00,

                                                                                    total_iliquido: linha_total_iliquido,

                                                                                    total_desconto: linha_total_desconto,
                                                                                    total_iliquido_com_desconto: linha_total_iliquido_desconto,

                                                                                    total_iva: linha_total_iva,
                                                                                    total_com_iva: linha_total_com_iva,
                                                                                    total_iva_apresentado:  parseFloat(linha_total_iva_apresentado).toFixed(2),
                                                                                    total_com_iva_apresentado: parseFloat(linha_total_com_iva).toFixed(2),
                                                                                    total_iliquido_apresentado: parseFloat(linha_total_iliquido).toFixed(2),
                                                                                
                                                                                    quantidade_aux_em_caso_de_aula: 0,

                                                                                    total_liquido: linha_total_com_iva
                                                                                }))
                                                                            }
                                        
                                                                            cart_final_aux.push({
                                                                                id: el.id,
                                                                                idp: '90',
                                                                                pista: true,
                                                                                name: language[lang].pista_aluguer, 
                                                                                category: '', 
                                                                                observation: '', 
                                                                                unit_price: (parseFloat(el_f.info.pista.price_s_vat)).toFixed(3), 
                                                                                unit_price_iva: (parseFloat(el_f.info.pista.price)).toFixed(2),
                                                                                quantity: el.quantity, 
                                                                                vat: el_f.info.pista.taxe, 
                                                                                total: (parseFloat(el_f.info.pista.price) * parseInt(el.quantity)).toFixed(2)
                                                                            })

                                                                            let linha_preco_unitario_2 = (parseFloat(el_f.info.pista.price_s_vat))
                                                                            let linha_taxa_iva_2 = el_f.info.pista.taxe
                                                                            // let linha_preco_unitario_com_iva_2 = (parseFloat(el_f.info.pista.price) * parseFloat(linha_taxa_iva_2) * 0.01)
                                                                            let linha_total_iliquido_2 = (parseFloat(linha_preco_unitario_2) * el.quantity)
                                                                            // let linha_total_liquido_2 = (parseFloat(linha_preco_unitario_com_iva_2) * el.quantity)
                                                                            let linha_total_desconto_2 = 0.00 // (parseFloat(linha_total_iliquido_2))  // total_iliquido * taxa_desconto_linha * 0.01
                                                                            let linha_total_iliquido_desconto_2 = (parseFloat(linha_total_iliquido_2) - parseFloat(linha_total_desconto_2))  // total_iliquido - total_desconto
                                                                            let linha_total_iva_2 = (parseFloat(linha_total_iliquido_2) * parseFloat(linha_taxa_iva_2) * 0.01) // total_iliquido_com_desconto * taxa_iva * 0.01
                                                                            let linha_total_com_iva_2 = (parseFloat(linha_total_iliquido_desconto_2) + parseFloat(linha_total_iva_2)) // total_iliquido_com_desconto + total_iva
                                                                            
                                                                            let linha_total_iva_apresentado_2 = (((parseFloat(el_f.info.pista.price) - parseFloat(el_f.info.pista.price_s_vat)) * parseInt(el.quantity))).toFixed(3)
                                                                            
                                                                            let aux_filter_9 = dados.current.info.documento.linhas.filter(el_filter => el_filter.id_produto === el_f.info.pista.id && (el_filter.pretence_ao_produto === el_f.id) /*&& el_filter.id_produto !== '90'*/)
                                                                            if(aux_filter_9.length > 0) {

                                                                            }
                                                                            else {
                                                                                set_dados(dados.current.info.documento.linhas.push({
                                                                                    // id_produto: el_f.id,
                                                                                    // id_sub_produto: el_f.info.pista.id,
                                                                                    id_produto: el_f.info.pista.id,
                                                                                    pretence_ao_produto: el_f.id,

                                                                                    designacao: language[lang].pista_aluguer,
                                                                                    subname: '',
                                                                                    observation: '',
                                                                                    unidade: 'un',
                                            
                                                                                    aula_marcada: '',
                                                                                    // show_qr: true,
                                                                                    id_produto_na_lista_linhas: parseInt(dados.current.info.documento.linhas.length) + 1,

                                                                                    quantidade: el.quantity,
                                                                                    preco_unitario: linha_preco_unitario_2, 

                                                                                    taxa_iva: ((parseFloat(linha_taxa_iva_2).toFixed(2))).toString(),
                                                                                    taxa_desconto_linha: 0.00,

                                                                                    total_iliquido: linha_total_iliquido_2,

                                                                                    total_desconto: linha_total_desconto_2,
                                                                                    total_iliquido_com_desconto: linha_total_iliquido_desconto_2,

                                                                                    total_iva: linha_total_iva_2,
                                                                                    total_com_iva: linha_total_com_iva_2,
                                                                                    total_iva_apresentado:  parseFloat(linha_total_iva_apresentado_2).toFixed(2),
                                                                                    total_com_iva_apresentado: parseFloat(linha_total_com_iva_2).toFixed(2),
                                                                                    total_iliquido_apresentado: parseFloat(linha_total_iliquido_2).toFixed(2),

                                                                                    quantidade_aux_em_caso_de_aula: 0,

                                                                                    total_liquido: linha_total_com_iva_2
                                                                                }))
                                                                            }

                                                                            total_iliquido_documento += parseFloat(linha_total_iliquido_desconto) + parseFloat(linha_total_iliquido_desconto_2)
                                                                            total_desconto_linha_documento += parseFloat(linha_total_desconto) +  parseFloat(linha_total_desconto_2)
                                                                        }
                                                                    })
                                                                })
                                                            }

                                                            if(cart_aux['equipments'] !== undefined && cart_aux['equipments'].length > 0) {
                                                                cart_aux['equipments'].forEach((el) => {
                                                                    res_e.forEach((el_e) => {
                                                                        if(el_e.id === el.id && parseInt(el.quantity) > 0) {

                                                                            // gerer_qr(dados.current.info['id_usado_para_gerar_pagamento'], dados.current.info.data, el.id, el.quantity) 

                                                                            if(total_vat_aux.length === 0) {
                                                                                total_aux += parseFloat(el_e.info.value.price) * parseInt(el.quantity)

                                                                                total_vat_aux.push({id: el_e.info.value.taxe, value: ((parseFloat(el_e.info.value.price) - parseFloat(el_e.info.value.price_s_vat)) * parseInt(el.quantity)).toFixed(3)})
                                                                            }
                                                                            else {
                                                                                total_aux += parseFloat(el_e.info.value.price) * parseInt(el.quantity)

                                                                                total_vat_aux.forEach(el_t => {
                                                                                    if(el_t.id === el_e.info.value.taxe) {
                                                                                        el_t.value = (parseFloat(el_t.value) + ((parseFloat(el_e.info.value.price) - parseFloat(el_e.info.value.price_s_vat)) * parseInt(el.quantity))).toFixed(3)
                                                                                    }
                                                                                    else {
                                                                                        total_vat_aux.push({id: el_t.taxe, value: ((parseFloat(el_e.info.value.price) - parseFloat(el_e.info.value.price_s_vat)) * parseInt(el.quantity)).toFixed(3)})
                                                                                    }
                                                                                })
                                                                            }

                                                                            cart_final_aux.push({
                                                                                id: el.id,
                                                                                name: (lang === 'pt' || el_e.info.language === undefined ? el_e.info.name : (el_e.info.language[lang_id] === undefined || el_e.info.language[lang_id] === null || el_e.info.language[lang_id].name === undefined || el_e.info.language[lang_id].name === '' ? el_e.info.name : el_e.info.language[lang_id].name)),
                                                                                //name: el_e.info.name, 
                                                                                category: (lang === 'pt' || el_e.info.language === undefined ? el_e.info.category : (el_e.info.language[lang_id] === undefined || el_e.info.language[lang_id] === null || el_e.info.language[lang_id].category === undefined || el_e.info.language[lang_id].category === '' ? el_e.info.category : el_e.info.language[lang_id].category)),
                                                                                //category: el_e.info.category, 
                                                                                observation: (lang === 'pt' || el_e.info.language === undefined ? el_e.info.observation : (el_e.info.language[lang_id] === undefined || el_e.info.language[lang_id] === null || el_e.info.language[lang_id].observation === undefined || el_e.info.language[lang_id].observation === '' ? el_e.info.observation : el_e.info.language[lang_id].observation)), 
                                                                                //observation: el_e.info.observation, 
                                                                                unit_price: (parseFloat(el_e.info.value.price_s_vat)).toFixed(3), 
                                                                                unit_price_iva: (parseFloat(el_e.info.value.price)).toFixed(2),
                                                                                quantity: el.quantity, 
                                                                                vat: el_e.info.value.taxe, 
                                                                                total: (parseFloat(el_e.info.value.price) * parseInt(el.quantity)).toFixed(2)
                                                                            })

                                                                            let linha_preco_unitario = (parseFloat(el_e.info.value.price_s_vat))
                                                                            let linha_taxa_iva = el_e.info.value.taxe
                                                                            // let linha_preco_unitario_com_iva = (parseFloat(el_e.info.value.price) * parseFloat(linha_taxa_iva) * 0.01)
                                                                            let linha_total_iliquido = (parseFloat(linha_preco_unitario) * el.quantity)
                                                                            // let linha_total_liquido = (parseFloat(linha_preco_unitario_com_iva) * el.quantity)
                                                                            let linha_total_desconto = 0.00 // (parseFloat(linha_total_iliquido))  // total_iliquido * taxa_desconto_linha * 0.01
                                                                            let linha_total_iliquido_desconto = (parseFloat(linha_total_iliquido) - parseFloat(linha_total_desconto))  // total_iliquido - total_desconto
                                                                            let linha_total_iva = (parseFloat(linha_total_iliquido) * parseFloat(linha_taxa_iva) * 0.01) // total_iliquido_com_desconto * taxa_iva * 0.01
                                                                            let linha_total_com_iva = (parseFloat(linha_total_iliquido_desconto) + parseFloat(linha_total_iva)) // total_iliquido_com_desconto + total_iva
                                                                            
                                                                            let linha_total_iva_apresentado = (((parseFloat(el_e.info.value.price) - parseFloat(el_e.info.value.price_s_vat)) * parseInt(el.quantity))).toFixed(3)
                                                                            
                                                                            let aux_filter_10 = dados.current.info.documento.linhas.filter(el_filter => (el_filter.id_produto === el_e.id && el_filter.pretence_ao_produto === el_e.id))
                                                                            if(aux_filter_10.length > 0) {

                                                                            }
                                                                            else {
                                                                                set_dados(dados.current.info.documento.linhas.push({
                                                                                    // id_produto: el_e.id,
                                                                                    id_produto: el_e.id,
                                                                                    pretence_ao_produto: el_e.id,
                                                                                    
                                                                                    designacao: el_e.info.name,
                                                                                    subname: '',
                                                                                    observation: el_e.info.observation, 
                                                                                    unidade: 'un',
                                            
                                                                                    aula_marcada: '',
                                                                                    // show_qr: true,
                                                                                    id_produto_na_lista_linhas: parseInt(dados.current.info.documento.linhas.length) + 1,

                                                                                    quantidade: el.quantity,
                                                                                    preco_unitario: linha_preco_unitario, 

                                                                                    taxa_iva: ((parseFloat(linha_taxa_iva).toFixed(2))).toString(),
                                                                                    taxa_desconto_linha: 0.00,

                                                                                    total_iliquido: linha_total_iliquido,

                                                                                    total_desconto: linha_total_desconto,
                                                                                    total_iliquido_com_desconto: linha_total_iliquido_desconto,

                                                                                    total_iva: linha_total_iva,
                                                                                    total_com_iva: linha_total_com_iva,
                                                                                    total_iva_apresentado:  parseFloat(linha_total_iva_apresentado).toFixed(2),
                                                                                    total_com_iva_apresentado: parseFloat(linha_total_com_iva).toFixed(2),
                                                                                    total_iliquido_apresentado: parseFloat(linha_total_iliquido).toFixed(2),
                                                                                
                                                                                    quantidade_aux_em_caso_de_aula: 0,

                                                                                    total_liquido: linha_total_com_iva
                                                                                }))
                                                                            }

                                                                            total_iliquido_documento += parseFloat(linha_total_iliquido_desconto)
                                                                            total_desconto_linha_documento += parseFloat(linha_total_desconto)
                                                                        }
                                                                    })
                                                                })
                                                            }

                                                            if(cart_aux['atividades'] !== undefined && cart_aux['atividades'].length > 0) {
                                                                cart_aux['atividades'].forEach((el) => {
                                                                    res_at.forEach((el_at) => {
                                                                        if(el_at.id === el.id && parseInt(el.quantity) > 0) {

                                                                            // gerer_qr(dados.current.info['id_usado_para_gerar_pagamento'], dados.current.info.data, el.id, el.quantity) 

                                                                            if(total_vat_aux.length === 0) {
                                                                                total_aux += parseFloat(el_at.info.value.price) * parseInt(el.quantity)

                                                                                total_vat_aux.push({id: el_at.info.value.taxe, value: ((parseFloat(el_at.info.value.price) - parseFloat(el_at.info.value.price_s_vat)) * parseInt(el.quantity)).toFixed(3)})
                                                                            }
                                                                            else {
                                                                                total_aux += parseFloat(el_at.info.value.price) * parseInt(el.quantity)

                                                                                total_vat_aux.forEach(el_t => {
                                                                                    if(el_t.id === el_at.info.value.taxe) {

                                                                                        el_t.value = (parseFloat(el_at.value) + ((parseFloat(el_at.info.value.price) - parseFloat(el_at.info.value.price_s_vat)) * parseInt(el.quantity))).toFixed(3)
                                                                                    }
                                                                                    else {
                                                                                
                                                                                        total_vat_aux.push({id: el_at.info.value.taxe, value: ((parseFloat(el_at.info.value.price) - parseFloat(el_at.info.value.price_s_vat)) * parseInt(el.quantity)).toFixed(3)})
                                                                                    }
                                                                                })
                                                                            }

                                                                            cart_final_aux.push({
                                                                                id: el.id,
                                                                                name: (lang === 'pt' || el_at.info.language === undefined ? el_at.info.name : (el_at.info.language[lang_id] === undefined || el_at.info.language[lang_id] === null || el_at.info.language[lang_id].name === undefined || el_at.info.language[lang_id].name === '' ? el_at.info.name : el_at.info.language[lang_id].name)),
                                                                                //name: el_at.info.name, 
                                                                                category: (lang === 'pt' || el_at.info.language === undefined ? el_at.info.category : (el_at.info.language[lang_id] === undefined || el_at.info.language[lang_id] === null || el_at.info.language[lang_id].category === undefined || el_at.info.language[lang_id].category === '' ? el_at.info.category : el_at.info.language[lang_id].category)),
                                                                                //category: el_at.info.category,
                                                                                observation: (lang === 'pt' || el_at.info.language === undefined ? el_at.info.observation : (el_at.info.language[lang_id] === undefined || el_at.info.language[lang_id] === null || el_at.info.language[lang_id].observation === undefined || el_at.info.language[lang_id].observation === '' ? el_at.info.observation : el_at.info.language[lang_id].observation)), 
                                                                                //observation: el_at.info.observation, 
                                                                                unit_price: (parseFloat(el_at.info.value.price_s_vat)).toFixed(3), 
                                                                                unit_price_iva: (parseFloat(el_at.info.value.price)).toFixed(2),
                                                                                quantity: el.quantity, 
                                                                                vat: el_at.info.value.taxe, 
                                                                                total: (parseFloat(el_at.info.value.price) * parseInt(el.quantity))
                                                                            })

                                                                            let linha_preco_unitario = (parseFloat(el_at.info.value.price_s_vat))
                                                                            let linha_taxa_iva = el_at.info.value.taxe
                                                                            // let linha_preco_unitario_com_iva = (parseFloat(el_at.info.value.price) * parseFloat(linha_taxa_iva) * 0.01)
                                                                            let linha_total_iliquido = (parseFloat(linha_preco_unitario) * el.quantity)
                                                                            // let linha_total_liquido = (parseFloat(linha_preco_unitario_com_iva) * el.quantity)
                                                                            let linha_total_desconto = 0.00 // (parseFloat(linha_total_iliquido))  // total_iliquido * taxa_desconto_linha * 0.01
                                                                            let linha_total_iliquido_desconto = (parseFloat(linha_total_iliquido) - parseFloat(linha_total_desconto))  // total_iliquido - total_desconto
                                                                            let linha_total_iva = (parseFloat(linha_total_iliquido) * parseFloat(linha_taxa_iva) * 0.01) // total_iliquido_com_desconto * taxa_iva * 0.01
                                                                            let linha_total_com_iva = (parseFloat(linha_total_iliquido_desconto) + parseFloat(linha_total_iva)) // total_iliquido_com_desconto + total_iva
                                                                            
                                                                            let linha_total_iva_apresentado = (((parseFloat(el_at.info.value.price) - parseFloat(el_at.info.value.price_s_vat)) * parseInt(el.quantity))).toFixed(3)
                                                                            
                                                                            let aux_filter_10 = dados.current.info.documento.linhas.filter(el_filter => (el_filter.id_produto === el_at.id && el_filter.pretence_ao_produto === el_at.id))
                                                                            if(aux_filter_10.length > 0) {

                                                                            }
                                                                            else {
                                                                                set_dados(dados.current.info.documento.linhas.push({
                                                                                    // id_produto: el_at.id,
                                                                                    id_produto: el_at.id,
                                                                                    pretence_ao_produto: el_at.id,
                                                                                    
                                                                                    designacao: el_at.info.name + ' - ' + el_at.info.category,
                                                                                    subname: '',
                                                                                    observation: el_at.info.observation, 
                                                                                    unidade: 'un',
                                            
                                                                                    aula_marcada: '',
                                                                                    // show_qr: true,
                                                                                    id_produto_na_lista_linhas: parseInt(dados.current.info.documento.linhas.length) + 1,

                                                                                    quantidade: el.quantity,
                                                                                    preco_unitario: linha_preco_unitario, 

                                                                                    taxa_iva: ((parseFloat(linha_taxa_iva).toFixed(2))).toString(),
                                                                                    taxa_desconto_linha: 0.00,

                                                                                    total_iliquido: linha_total_iliquido,

                                                                                    total_desconto: linha_total_desconto,
                                                                                    total_iliquido_com_desconto: linha_total_iliquido_desconto,

                                                                                    total_iva: linha_total_iva,
                                                                                    total_com_iva: linha_total_com_iva,
                                                                                    total_iva_apresentado:  parseFloat(linha_total_iva_apresentado).toFixed(2),
                                                                                    total_com_iva_apresentado: parseFloat(linha_total_com_iva).toFixed(2),
                                                                                    total_iliquido_apresentado: parseFloat(linha_total_iliquido).toFixed(2),
                                                                                
                                                                                    quantidade_aux_em_caso_de_aula: 0,

                                                                                    total_liquido: linha_total_com_iva
                                                                                }))
                                                                            }

                                                                            total_iliquido_documento += parseFloat(linha_total_iliquido_desconto)
                                                                            total_desconto_linha_documento += parseFloat(linha_total_desconto)
                                                                        }
                                                                    })
                                                                })
                                                            }

                                                            if(cart_aux['aulas'] !== undefined && cart_aux['aulas'].length > 0) {
                                                                cart_aux['aulas'].forEach((el) => {
                                                                    res_a.forEach((el_a) => {
                                                                        if(el_a.id === el.id && parseInt(el.quantity) > 0) {

                                                                            // gerer_qr(dados.current.info['id_usado_para_gerar_pagamento'], dados.current.info.data, el.id, el.quantity) 
                                                                            if(total_vat_aux.length === 0) {
                                                                                total_aux += parseFloat(el_a.info.detail.price) * parseInt(el.quantity)

                                                                                total_vat_aux.push({id: el_a.info.value.taxe, value: ((parseFloat(el_a.info.detail.price) - parseFloat(el_a.info.detail.price_s_vat)) * parseInt(el.quantity)).toFixed(3)})
                                                                            }
                                                                            else {
                                                                                total_aux += parseFloat(el_a.info.detail.price) * parseInt(el.quantity)

                                                                                total_vat_aux.forEach(el_t => {
                                                                                    if(el_t.id === el_a.info.value.taxe) {
                                                                                        el_t.value = (parseFloat(el_t.value) + ((parseFloat(el_a.info.detail.price) - parseFloat(el_a.info.detail.price_s_vat)) * parseInt(el.quantity))).toFixed(3)
                                                                                    }
                                                                                    else {
                                                                                        total_vat_aux.push({id: el_a.info.value.taxe, value: ((parseFloat(el_a.info.detail.price) - parseFloat(el_a.info.detail.price_s_vat)) * parseInt(el.quantity)).toFixed(3)})
                                                                                    }
                                                                                })
                                                                            }

                                                                            cart_final_aux.push({
                                                                                id: el.id,
                                                                                name: (lang === 'pt' || el_a.info.language === undefined ? el_a.info.name : (el_a.info.language[lang_id] === undefined || el_a.info.language[lang_id] === null || el_a.info.language[lang_id].name === undefined || el_a.info.language[lang_id].name === '' ? el_a.info.name : el_a.info.language[lang_id].name)),
                                                                                //name: el_a.info.name, 
                                                                                category: (lang === 'pt' || el_a.info.language === undefined ? el_a.info.category : (el_a.info.language[lang_id] === undefined || el_a.info.language[lang_id] === null || el_a.info.language[lang_id].category === undefined || el_a.info.language[lang_id].category === '' ? el_a.info.category : el_a.info.language[lang_id].category)),
                                                                                //category: el_a.info.category,
                                                                                observation: (lang === 'pt' || el_a.info.language === undefined ? el_a.info.observation : (el_a.info.language[lang_id] === undefined || el_a.info.language[lang_id] === null || el_a.info.language[lang_id].observation === undefined || el_a.info.language[lang_id].observation === '' ? el_a.info.observation : el_a.info.language[lang_id].observation)), 
                                                                                //observation: el_a.info.observation, 
                                                                                unit_price: (parseFloat(el_a.info.detail.price_s_vat)).toFixed(3), 
                                                                                unit_price_iva: (parseFloat(el_a.info.detail.price)).toFixed(2),
                                                                                quantity: el.quantity, 
                                                                                vat: el_a.info.detail.taxe, 
                                                                                total: (parseFloat(el_a.info.detail.price) * parseInt(el.quantity)).toFixed(2)
                                                                            })

                                                                            console.log('cart_final_aux', cart_final_aux)

                                                                            let linha_preco_unitario = (parseFloat(el_a.info.detail.price_s_vat))
                                                                            let linha_taxa_iva = el_a.info.detail.taxe
                                                                            // let linha_preco_unitario_com_iva = (parseFloat(el_a.info.detail.price) * parseFloat(linha_taxa_iva) * 0.01)
                                                                            let linha_total_iliquido = (parseFloat(linha_preco_unitario) * el.quantity)
                                                                            // let linha_total_liquido = (parseFloat(linha_preco_unitario_com_iva) * el.quantity)
                                                                            let linha_total_desconto = 0.00 // (parseFloat(linha_total_iliquido))  // total_iliquido * taxa_desconto_linha * 0.01
                                                                            let linha_total_iliquido_desconto = (parseFloat(linha_total_iliquido) - parseFloat(linha_total_desconto))  // total_iliquido - total_desconto
                                                                            let linha_total_iva = (parseFloat(linha_total_iliquido) * parseFloat(linha_taxa_iva) * 0.01) // total_iliquido_com_desconto * taxa_iva * 0.01
                                                                            let linha_total_com_iva = (parseFloat(linha_total_iliquido_desconto) + parseFloat(linha_total_iva)) // total_iliquido_com_desconto + total_iva
                                                                            
                                                                            let linha_total_iva_apresentado = (((parseFloat(el_a.info.detail.price) - parseFloat(el_a.info.detail.price_s_vat)) * parseInt(el.quantity))).toFixed(3)

                                                                            let aux_filter_10 = dados.current.info.documento.linhas.filter(el_filter => (el_filter.id_produto === el_a.id && el_filter.pretence_ao_produto === el_a.id))
                                                                            if(aux_filter_10.length > 0) {

                                                                            }
                                                                            else {
                                                                                set_dados(dados.current.info.documento.linhas.push({
                                                                                    // id_produto: el_a.id,
                                                                                    id_produto: el_a.id,
                                                                                    pretence_ao_produto: el_a.id,
                                                                                    
                                                                                    designacao: el_a.info.name + ' - ' + el_a.info.category,
                                                                                    subname: '',
                                                                                    observation: el_a.info.observation, 
                                                                                    unidade: 'un',
                                            
                                                                                    aula_marcada: '',
                                                                                    // show_qr: false,
                                                                                    id_produto_na_lista_linhas: parseInt(dados.current.info.documento.linhas.length) + 1,

                                                                                    quantidade: el.quantity,
                                                                                    preco_unitario: linha_preco_unitario, 

                                                                                    taxa_iva: ((parseFloat(linha_taxa_iva).toFixed(2))).toString(),
                                                                                    taxa_desconto_linha: 0.00,

                                                                                    total_iliquido: linha_total_iliquido,

                                                                                    total_desconto: linha_total_desconto,
                                                                                    total_iliquido_com_desconto: linha_total_iliquido_desconto,

                                                                                    total_iva: linha_total_iva,
                                                                                    total_com_iva: linha_total_com_iva,
                                                                                    total_iva_apresentado:  parseFloat(linha_total_iva_apresentado).toFixed(2),
                                                                                    total_com_iva_apresentado: parseFloat(linha_total_com_iva).toFixed(2),
                                                                                    total_iliquido_apresentado: parseFloat(linha_total_iliquido).toFixed(2),
                                                                                
                                                                                    quantidade_aux_em_caso_de_aula: el.quantity,

                                                                                    total_liquido: linha_total_com_iva
                                                                                }))
                                                                            }

                                                                            total_iliquido_documento += parseFloat(linha_total_iliquido_desconto)
                                                                            total_desconto_linha_documento += parseFloat(linha_total_desconto)

                                                                            tem_aulas = true
                                                                        }
                                                                    })
                                                                })
                                                            }

                                                            // subtotal_aux = total_aux
                                                            // total_vat_aux.forEach(el_iva => {
                                                            //     subtotal_aux -= parseFloat(el_iva.value).toFixed(3)
                                                            //     total_iva_documento += parseFloat(el_iva.value)
                                                            // })

                                                            set_dados(dados.current.info.documento.total_iliquido_documento = parseFloat(total_iliquido_documento).toFixed(2), )
                                                            set_dados(dados.current.info.documento.total_desconto_linha_documento = parseFloat(total_desconto_linha_documento).toFixed(2), )
                                                            set_dados(dados.current.info.documento.taxa_desconto_global = parseFloat(taxa_desconto_global).toFixed(2), )

                                                            valor_desconto_global_a_aplicar = parseFloat(total_desconto_linha_documento).toFixed(2) + parseFloat(taxa_desconto_global).toFixed(2)
                                                            set_dados(dados.current.info.documento.valor_desconto_global_a_aplicar = parseFloat(valor_desconto_global_a_aplicar).toFixed(2), )

                                                            total_iliquido_documento_com_deconto_linha_e_global = parseFloat(total_iliquido_documento).toFixed(2) - parseFloat(( parseFloat(total_desconto_linha_documento).toFixed(2) + parseFloat(valor_desconto_global_a_aplicar).toFixed(2)) )
                                                            set_dados(dados.current.info.documento.total_iliquido_documento_com_deconto_linha_e_global = total_iliquido_documento_com_deconto_linha_e_global, )

                                                            total_documento = total_aux
                                                            //set_dados(dados.current.info.documento.valor_desconto_global_a_aplicar = parseFloat(valor_desconto_global_a_aplicar).toFixed(2), )
                                                            let get_total_iva_documento = (linhas) => {

                                                                let tmp_total_iva = 0.00
                                                                linhas.forEach(linha => {
                                                                    //let v_tmp_iliq = parseFloat(linha.total_iliquido_com_desconto) - parseFloat(valor_desconto_global_a_aplicar)
                                                                    //let v_tmp_iva = parseFloat(v_tmp_iliq) * parseFloat(linha.taxa_iva) * 0.01
                                                                    //tmp_total_iva += parseFloat(v_tmp_iva)
                                                                    tmp_total_iva += parseFloat(linha.total_iva)
                                                                })

                                                                return tmp_total_iva
                                                            }
                                                            set_dados(dados.current.info.documento.total_iva_documento = parseFloat(get_total_iva_documento(dados.current.info.documento.linhas)).toFixed(3), )
                                                            set_dados(dados.current.info.documento.total_documento = parseFloat(total_documento).toFixed(2), )
                                                            
                                                            set_dados(dados.current.info.tem_aulas = tem_aulas, )

                                                            // console.log(cart_final_aux)
                                                            set_cart_final(cart_final_aux)
                                                            set_total(total_aux)
                                                            set_subtotal(subtotal_aux)
                                                            set_total_vat(total_vat_aux)
                                                            setLoading(false)

                                                            set_linhas_aux(dados.current.info.documento.linhas)

                                                            if(total_aux === 0)
                                                                setRedirect(true);

                                                            if(getTokenInfo() !== undefined && getTokenInfo() !== null && parseInt(getTokenInfo().id) === parseInt('141')) {
                                                                Swal.fire('', JSON.stringify(dados.current.info), 'info')
                                                            }
                                                            console.log('encomenda', JSON.stringify(dados.current.info))
                                                            console.log('encomenda', (dados.current.info))

                                                    }) 
                                            })
                                    })

                            })
                    })
            })
    }, [lang, lista_qr, refresh]); 

    /*
    const compose_email =  async (id_encomenda, payment_type, payment_entity, payment_reference, data_limite) => {

        let today = new Date();
        let today_aux = String(today.getDate()).padStart(2, '0') + '-' + String(today.getMonth() + 1) .padStart(2, '0') + '-' + today.getFullYear();

        let mail_content = '<table width="600" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 600px; background-color:' + Hosts.EMAIL_BACKGROUND_COLOR +';border:1px solid transparent;border-collapse:collapse;">' +
            '<tr style="border-collapse: collapse;">' +
                '<td align="left" style="padding: 30px 40px 0px; font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.EMAIL_TEXT_SIZE +'; line-height:' + Hosts.EMAIL_TEXT_LINE_HEIGHT  +'; color:' + Hosts.EMAIL_TEXT_COLOR +'">' +
                    '<div>' +
                        '<span><b>'+ language[lang].estado +': </b></span>' +
                        '<span><label>' + language[lang].aguardar_pagamento + '</label></span>' +
                    '</div>' +
                '</td>' + 
                '<td align="right" style="padding: 30px 40px 0px; font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.EMAIL_TEXT_SIZE +'; line-height:' + Hosts.EMAIL_TEXT_LINE_HEIGHT  +'; color:' + Hosts.EMAIL_TEXT_COLOR +'">' +
                    '<div>'+
                        '<span>'+ today_aux + '</span>'+
                    '</div>'+
                '</td>' +
            '</tr>' +

            '<tr>' +
                '<td colspan="2" align="right" style="padding: 0px 40px; height: 15px;"></td>' +
            '</tr>' +

            '<tr style="border-collapse: collapse;">' +
                '<td align="left" style="padding-left: 40px; font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.EMAIL_TEXT_SIZE +'; line-height:' + Hosts.EMAIL_TEXT_LINE_HEIGHT  +'; color:' + Hosts.EMAIL_TEXT_COLOR +'">' +
                    '<div>'+
                        '<span><b>'+ language[lang].num_encomenda + ':</b> ' + id_encomenda + '</span>'+
                    '</div>'+
                '</td>' +
            '</tr>' +

            '<tr>' +
                '<td colspan="2" align="right" style="padding: 0px 40px; height: 15px;"></td>' +
            '</tr>' +

            '<tr style="border-collapse: collapse;">' +
                '<td colspan="2" align="left" style="padding: 0px 40px; font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.EMAIL_TEXT_SIZE +'; line-height:' + Hosts.EMAIL_TEXT_LINE_HEIGHT  +'; color:' + Hosts.EMAIL_TEXT_COLOR +'">' +
                    '<div>'+
                        '<span><b>'+ language[lang].name +': </b><label>' + dados.current.info.documento.user_info.name + '</label></span>' +
                        '<br/>'+
                        '<span"><b>Email: </b><label>' + dados.current.info.documento.user_info.contacts[0].email + '</label></span>'+
                        '<br/>'+
                        '<span><b>'+ language[lang].dados_vat +': </b><label>' + dados.current.info.documento.user_info.vat + '</label></span>' +
                    '</div>'+
                '</td>' +
            '</tr>' +

            '<tr>' +
                '<td colspan="2" align="right" style="padding: 0px 40px; height: 15px;"></td>' +
            '</tr>' +
        '</table>';

        if(dados.current.info['metodo_de_pagamento'] === '0') {
            mail_content += '<table width="600" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 600px; padding: 0px 30px 15px; background-color:' + Hosts.EMAIL_BACKGROUND_COLOR +'; border:1px solid transparent; border-collapse:collapse;">' +
                '<tr style="border-collapse: collapse;">' +
                    '<td align="left" style="padding: 0px 40px; font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.EMAIL_TEXT_SIZE +'; line-height:' + Hosts.EMAIL_TEXT_LINE_HEIGHT  +'; color:' + Hosts.EMAIL_TEXT_COLOR +'">' +
                        '<div>' +
                            '<b>' + language[lang].dados_pagamento +'</b>'+
                            '<br/>' +
                            '<span><label>Pagamento por Cartão.</label></span>' +
                        '</div>'+
                    '</td>' +
                '</tr>' +
                '<tr style="border-collapse: collapse;">' +
                    '<td colspan="2" align="left" style="padding: 0px 40px; font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.EMAIL_TEXT_SIZE +'; line-height:' + Hosts.EMAIL_TEXT_LINE_HEIGHT  +'; color:' + Hosts.EMAIL_TEXT_COLOR +'">' +
                        '<div>' +
                            '<span><small>' + language[lang].data_limite_txt + '</small></span>' +
                        '</div>' +
                    '</td>' +
                '</tr>' +

                '<tr>' +
                    '<td colspan="2" align="right" style="padding: 0px 40px; height: 30px;"></td>' +
                '</tr>' +
            '</table>';
        }
        else if(dados.current.info['metodo_de_pagamento'] === '-1') {
            mail_content += '<table width="600" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 600px; padding: 0px 30px 15px; background-color:' + Hosts.EMAIL_BACKGROUND_COLOR +'; border:1px solid transparent; border-collapse:collapse;">' +
                '<tr style="border-collapse: collapse;">' +
                    '<td align="left" style="padding: 0px 40px; font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.EMAIL_TEXT_SIZE +'; line-height:' + Hosts.EMAIL_TEXT_LINE_HEIGHT  +'; color:' + Hosts.EMAIL_TEXT_COLOR +'">' +
                        '<div>' +
                            '<b>' + language[lang].dados_pagamento +'</b>'+
                            '<br/>' +
                            '<span><label>Pagamento por MB Way para o número ' + mbway_mobile_phone + '.</label></span>' +
                            '<br/>'+
                            '<span><b>'+ language[lang].data_limite +':</b> ' + data_limite + '</span>' + 
                        '</div>'+
                    '</td>' +
                '</tr>' +
                '<tr style="border-collapse: collapse;">' +
                    '<td colspan="2" align="left" style="padding: 0px 40px; font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.EMAIL_TEXT_SIZE +'; line-height:' + Hosts.EMAIL_TEXT_LINE_HEIGHT  +'; color:' + Hosts.EMAIL_TEXT_COLOR +'">' +
                        '<div>' +
                            '<span><small>' + language[lang].data_limite_txt + '</small></span>' +
                        '</div>' +
                    '</td>' +
                '</tr>' +

                '<tr>' +
                    '<td colspan="2" align="right" style="padding: 0px 40px; height: 30px;"></td>' +
                '</tr>' +
            '</table>';

        }
        else if(dados.current.info['metodo_de_pagamento'] === '1') {
            mail_content += '<table width="600" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 600px; padding: 0px 30px 15px; background-color:' + Hosts.EMAIL_BACKGROUND_COLOR +'; border:1px solid transparent; border-collapse:collapse;">' +
                '<tr style="border-collapse: collapse;">' +
                    '<td align="left" style="padding-left: 40px; font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.EMAIL_TEXT_SIZE +'; line-height:' + Hosts.EMAIL_TEXT_LINE_HEIGHT  +'; color:' + Hosts.EMAIL_TEXT_COLOR +'">' +
                        '<div>' +
                            '<b>' + language[lang].dados_pagamento +'</b>'+
                            '<br/>' +
                            '<span><b>'+ language[lang].entity +': </b><label>' + payment_entity + '</label></span>' +
                            '<br/>'+
                            '<span><b>'+ language[lang].reference +': </b><label>' + payment_reference + '</label></span>' +
                            '<br/>'+
                            '<span><b>'+ language[lang].total +': </b><label>' + parseFloat(total).toFixed(2) + '€</label></span>' +
                            '<br/>'+
                            '<span><b>'+ language[lang].data_limite +':</b> ' + data_limite + '</span>' +
                        '</div>' +
                    '</td>' +
                    // '<td align="right" style="padding-right: 40px; font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.EMAIL_TEXT_SIZE +'; line-height:' + Hosts.EMAIL_TEXT_LINE_HEIGHT  +'; color:' + Hosts.EMAIL_TEXT_COLOR +'">' +
                    //     '<div>' +
                    //         '<span><b>'+ language[lang].estado +': </b></span>' +
                    //         '<br/>'+
                    //         '<span><label>' + language[lang].aguardar_pagamento + '</label></span>' +
                    //     '</div>' +
                    // '</td>' +
                '</tr>' +
                '<tr style="border-collapse: collapse;">' +
                    '<td colspan="2" align="left" style="padding: 0px 40px; font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.EMAIL_TEXT_SIZE +'; line-height:' + Hosts.EMAIL_TEXT_LINE_HEIGHT  +'; color:' + Hosts.EMAIL_TEXT_COLOR +'">' +
                        '<div>' +
                            '<span><small>' + language[lang].data_limite_txt + '</small></span>' +
                        '</div>' +
                    '</td>' +
                '</tr>' +

                '<tr>' +
                    '<td colspan="2" align="right" style="padding: 0px 40px; height: 30px;"></td>' +
                '</tr>' +
            '</table>';
        }

        if(cart_final !== undefined) {
            mail_content += '<table width="600" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 600px; background-color:' + Hosts.EMAIL_BACKGROUND_COLOR +'; border:1px solid transparent; border-collapse:collapse;">' +
                '<tbody>' +
                    '<tr>' +
                        '<td style="padding: 0px 40px;">' + 
                            '<table style="width: 100%;">'
                
                                cart_final.forEach((el) => {
                                    mail_content += '<tr style="border-collapse: collapse;">' +
                                        '<td align="left" style=" font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.EMAIL_TEXT_SIZE +'; line-height:' + Hosts.EMAIL_TEXT_LINE_HEIGHT  +'; color:' + Hosts.EMAIL_TEXT_COLOR +'; border-bottom: 1px solid ' + Hosts.EMAIL_TEXT_COLOR + ';">' +
                                            '<div>' +
                                                '<b>' + el.name + '</b>' +
                                                '<br />' + 
                                                '' + (el.observation !== undefined ? el.observation : '') + '' +
                                            '</div>' +
                                        '</td>' +
                                        '<td align="right" style="min-width: 100px; font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.EMAIL_TEXT_SIZE +'; line-height:' + Hosts.EMAIL_TEXT_LINE_HEIGHT  +'; color:' + Hosts.EMAIL_TEXT_COLOR +'; border-bottom: 1px solid ' + Hosts.EMAIL_TEXT_COLOR + ';">' +
                                            '' + el.quantity + ' x ' + (parseFloat(el.total) / parseInt(el.quantity)).toFixed(2) + ' €' +
                                            '<br />' + 
                                            '<b>' + parseFloat(el.total).toFixed(2) + ' €</b>' +
                                        '</td>' +
                                    '</tr>';
                                })

                                mail_content += '<tr>' +
                                    '<td colspan="2" align="right" style="height: 15px;"></td>' +
                                '</tr>'

                                mail_content += '<tr style="border-collapse: collapse;">' +
                                    '<td align="right"  style="font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.EMAIL_TEXT_SIZE +'; line-height:' + Hosts.EMAIL_TEXT_LINE_HEIGHT  +'; color:' + Hosts.EMAIL_TEXT_COLOR +'"><b>' + language[lang].subtotal + '</b></td>' +
                                    '<td align="right" style="font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.EMAIL_TEXT_SIZE +'; line-height:' + Hosts.EMAIL_TEXT_LINE_HEIGHT  +'; color:' + Hosts.EMAIL_TEXT_COLOR +'">' + parseFloat(subtotal).toFixed(2) + '€</td>' +
                                '</tr>'

                                total_vat.forEach((el_total_vat) => {
                                    mail_content += '<tr>' +
                                        '<td align="right" style="font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.EMAIL_TEXT_SIZE +'; line-height:' + Hosts.EMAIL_TEXT_LINE_HEIGHT  +'; color:' + Hosts.EMAIL_TEXT_COLOR +'"><b>' + language[lang].vat + ' (' + el_total_vat.id + '%)</b></td>' +
                                        '<td align="right" style="font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.EMAIL_TEXT_SIZE +'; line-height:' + Hosts.EMAIL_TEXT_LINE_HEIGHT  +'; color:' + Hosts.EMAIL_TEXT_COLOR +'">' + el_total_vat.value + '€</td>' +
                                    '</tr>'
                                })

                                mail_content += '<tr>' +
                                    '<td align="right" style="font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.EMAIL_TEXT_SIZE +'; line-height:' + Hosts.EMAIL_TEXT_LINE_HEIGHT  +'; color:' + Hosts.EMAIL_TEXT_COLOR +'"><b>' + language[lang].total + '</b></td>' +
                                    '<td align="right" style="font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.EMAIL_TEXT_SIZE +'; line-height:' + Hosts.EMAIL_TEXT_LINE_HEIGHT  +'; color:' + Hosts.EMAIL_TEXT_COLOR +'">' + parseFloat(total).toFixed(2) + '€</td>' +
                                '</tr>'

                                mail_content += '<tr>' +
                                    '<td colspan="2" align="right" style="height: 30px;"></td>' +
                                '</tr>'

            mail_content += '</table>' + 
                        '</td>' +
                    '</tr>' + 
                '</tbody>' + 
            '</table>'
        }

        functionSendMail(Hosts.contactFrom, [dados.current.info.documento.user_info.contacts[0].email], [Hosts.contactFrom], Hosts.webSiteTitle + ' - ' + language[lang].contact_form, mail_content, [])
            .then((res) => {
                if(res === true) {
                    

                    if(payment_type === 'cc') {

                    }
                    else {
                        Swal.fire({
                            title: language[lang].contact_form,
                            text: language[lang].sent_successfully,
                            icon: 'success',
                            //showCancelButton: true,
                            confirmButtonColor: '#08396a',
                            //confirmButtonText: 'Tentar novamente',
                        })
                    }

                }
                else {
                    console.log('erro')
                }
            })

    }
    */

    const handleSubmit = async e => {
		e.preventDefault();

        if(getTokenInfo() !== undefined && getTokenInfo() !== null && parseInt(getTokenInfo().id) === parseInt('141')) {
            Swal.fire('', JSON.stringify(dados.current.info), 'info')

            return
        }
        else {

            Swal.fire({
                title: language[lang].confirm_purchase_title,
                text: language[lang].confirm_purchase_text,
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#0095e8',
                cancelButtonColor: '#d9214e',
                confirmButtonText: language[lang].confirm_purchase_confirm,
                cancelButtonText: language[lang].confirm_purchase_cancel
            })
            .then(async (result) => {
                if (result.isConfirmed) {

                    dados.current.info['qr'] = lista_qr

                    let date_today = new Date()
                    let timestamp_aux = get_data_func()
        
                    dados.current.info.data = date_today.getFullYear() + '-' + (date_today.getMonth() + 1).toString().padStart(2, '0') + '-' + date_today.getDate().toString().padStart(2, '0')
                    dados.current.info.timestamp = timestamp_aux
        
                    if(getTokenInfo() !== undefined && getTokenInfo() !== null && getTokenInfo().profile === 'Cliente') {
                        let id_pagamento = Date.now() + '' + getTokenInfo().id
                        dados.current.info['id_usado_para_gerar_pagamento'] = parseInt(id_pagamento)
                    }

                    if(dados.current.info.documento.user_info.vat === '')
                        dados.current.info.documento.user_info.vat = '999999990'

                    // await mutex.runExclusive(async () => {

                        await reqPOST(`${Hosts.SIMCore_API}/store/order`, dados.current)
                            // .then(() => { 

                                if(dados.current.info['metodo_de_pagamento'] === '-1') {
                                    await get_payment_data('mbw', '')
                                }
                                else if(dados.current.info['metodo_de_pagamento'] === '0') {
                                    await get_payment_data('cc', '')
                                }
                                else if(dados.current.info['metodo_de_pagamento'] === '1') {
                                    await get_payment_data('mb', '')
                                }

                                // LINPAR CARRINHO
                                // my_local_storage.removeItem('snowCart')

                                // Swal.fire(language[lang].confirm_purchase_success, '', 'info')
                                //props.history.push(endpoints.list) 
                            // })
                            // .catch(() => Swal.fire(language[lang].confirm_purchase_error, '', 'info'))
                            
                    // })

                }
            })

        }
	}

    const handle_change_info_documento_userinfo = (e) => {
		const { name, value } = e.target;

		set_dados(dados.current.info.documento.user_info[name] = value)

		// console.log('Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«')
		// console.log('Dados Current:' + JSON.stringify(dados))
	}

    const handle_change_info_documento_userinfo_addresses = (e) => {
		const { name, value } = e.target;

		set_dados(dados.current.info.documento.user_info.addresses[0][name] = value)

		// console.log('Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«')
		// console.log('Dados Current:' + JSON.stringify(dados))
	}

    const handle_change_info_documento_userinfo_contacts = (e) => {
		const { name, value } = e.target;

		set_dados(dados.current.info.documento.user_info.contacts[0][name] = value)

		// console.log('Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«Â«')
		// console.log('Dados Current:' + JSON.stringify(dados))
	}

    const validate_step = (i) => {

        console.log('i', i)

        if(i === '1') {
            if(getTokenInfo() !== undefined && getTokenInfo() !== null && getTokenInfo().profile === 'Cliente') {
                set_step(i)
            }
            else {
                //Swal.fire('', language[lang].confirm_login_error, 'info')

                Swal.fire({
                    title: '',
                    html: language[lang].confirm_login_error,
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonColor: '#0095e8',
                    cancelButtonColor: '#d9214e',
                    confirmButtonText: language[lang].yes,
                    cancelButtonText: language[lang].cancel
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        window.open(Hosts.WEB_BACKOFFICE_URI, '_self')
                    }
                })
            }
        }
        if(i === '2') {
            if((dados.current.info.documento.user_info.name).trim() !== '' 
                && (dados.current.info.documento.user_info.vat).trim() !== ''
                && (dados.current.info.documento.user_info.contacts[0].email).trim() !== ''
                && agree) {

                set_step(i)
            }
            else if(!agree) {
                Swal.fire('', language[lang].confirm_agree_error, 'info')
            }
            // else if(dados.current.info.documento.user_info.addresses[0].address_detail === '') {
            // }
            // else if(dados.current.info.documento.user_info.addresses[0].localidade === '') {
            // }
            // else if(dados.current.info.documento.user_info.addresses[0].postal_code === '') {
            // }
            // else if(dados.current.info.documento.user_info.addresses[0].country === '') {
            // }
            else {
                Swal.fire('', language[lang].confirm_form_error, 'info')
            }
        }
        if(i === '3') {
            set_step(i)
        }

    } 

    const set_paymet = async (type, id) => {
        console.log(type)

        set_dados(dados.current.info['metodo_de_pagamento'] = id)

        if(getTokenInfo() !== undefined && getTokenInfo() !== null && getTokenInfo().profile === 'Cliente') {
            let id_pagamento_2 = Date.now() + '' + getTokenInfo().id
            set_dados(dados.current.info['id_usado_para_gerar_pagamento'] = parseInt(id_pagamento_2))
        }

        // Swal.fire('', JSON.stringify(dados.current.info.documento), 'info')

        if(type === 'mbw') {
            const { value: formValues } = await Swal.fire({
                title: 'Pagamento por MBWay',
                html:
                    '<label>Número de telemóvel associado à aplicação MBWay</label>' +
                    '<input id="swal-input1" type="text" class="form-control mb-2" required>',
                focusConfirm: false,
                showCancelButton: true,
                cancelButtonText: language[lang].cancel,
                preConfirm: () => {
                    if(document.getElementById('swal-input1').value === '') {
                        Swal.fire('', document.getElementById('swal-input1').value, 'info')
                    }
                    else {
                        return [
                            document.getElementById('swal-input1').value
                        ]
                    }
                }
            })

            if (formValues) {
                if(formValues[0] !== undefined && formValues[0] !== '') {
                    set_mbway_mobile_phone(formValues[0])

                    Swal.fire({
                        title: language[lang].confirm_purchase_title,
                        text: language[lang].confirm_purchase_text,
                        icon: 'question',
                        showCancelButton: true,
                        confirmButtonColor: '#0095e8',
                        cancelButtonColor: '#d9214e',
                        confirmButtonText: language[lang].confirm_purchase_confirm,
                        cancelButtonText: language[lang].confirm_purchase_cancel
                    })
                    .then(async (result) => {
                        if (result.isConfirmed) {
            
                            dados.current.info['qr'] = lista_qr
                            dados.current.info['metodo_de_pagamento'] = id
            
                            if(dados.current.info.documento.user_info.vat === '')
                                dados.current.info.documento.user_info.vat = '999999990'

                            /*
                            let x = []
                            cart_final.forEach(el_c => {
                                let y = 0
                                dados.current.info.documento.linhas.forEach(el => {

                                    if(y === 0) {
                                        if((el_c.idp !== undefined && el.id_produto === el_c.idp)) {
                                            x.push(el)
                                            y++
                                        }
                                        else if(el.id_produto === el_c.id && el_c.pista === undefined) {
                                            x.push(el)
                                            y++
                                        }
                                    }

                                })
                            })
                            */
                            // REVER RETIRAR PRODUTO
                            // dados.current.info.documento.linhas = x


                            // if(getTokenInfo() !== undefined && getTokenInfo() !== null && parseInt(getTokenInfo().id) === parseInt('141')) {
                            //    Swal.fire('', JSON.stringify(dados.current.info), 'info')
                    
                            //    return
                           // }
                            //else {


                                // await mutex.runExclusive(async () => {

                                    await reqPOST(`${Hosts.SIMCore_API}/store/order`, dados.current)
                                        // .then(async () => { 
                    
                                            if(dados.current.info['metodo_de_pagamento'] === '-1') {
                                                await get_payment_data('mbw', formValues[0])
                                            }
                                            else if(dados.current.info['metodo_de_pagamento'] === '0') {
                                                await get_payment_data('cc', '')
                                            }
                                            else if(dados.current.info['metodo_de_pagamento'] === '1') {
                                                await get_payment_data('mb', '')
                                            }
                    
                                            // LINPAR CARRINHO
                                            // my_local_storage.removeItem('snowCart')
                    
                                            // Swal.fire(language[lang].confirm_purchase_success, '', 'info')
                                            //props.history.push(endpoints.list) 
                                        // })
                                        // .catch(() => Swal.fire(language[lang].confirm_purchase_error, '', 'info'))
                                
                                // })
                            // }
                
                        }
                    })
                }
                else {

                    Swal.fire({
                    title: 'Pagamento por MBWay',
                    html: 'É obrigatório introduzir o número de telemóvel associado à aplicação MBWay',
                    timer: 2000,
                    timerProgressBar: true,
                    didOpen: () => {
                    },
                    willClose: () => {
                        set_paymet(type, id)
                    }
                    }).then((result) => {
                    /* Read more about handling dismissals below */
                    if (result.dismiss === Swal.DismissReason.timer) {
                        set_paymet(type, id)
                    }
                    })

                }
            }

        }
        else {

            Swal.fire({
                title: language[lang].confirm_purchase_title,
                text: language[lang].confirm_purchase_text,
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#0095e8',
                cancelButtonColor: '#d9214e',
                confirmButtonText: language[lang].confirm_purchase_confirm,
                cancelButtonText: language[lang].confirm_purchase_cancel
            })
            .then(async (result) => {
                if (result.isConfirmed) {
    
                    dados.current.info['qr'] = lista_qr
                    dados.current.info['metodo_de_pagamento'] = id
    
                    if(dados.current.info.documento.user_info.vat === '')
                        dados.current.info.documento.user_info.vat = '999999990'

                    /*
                    let x = []
                    cart_final.forEach(el_c => {
                        let y = 0
                        dados.current.info.documento.linhas.forEach(el => {

                            if(y === 0) {
                                if((el_c.idp !== undefined && el.id_produto === el_c.idp)) {
                                    x.push(el)
                                    y++
                                }
                                else if(el.id_produto === el_c.id && el_c.pista === undefined) {
                                    x.push(el)
                                    y++
                                }
                            }

                        })
                    })
                    */
                    // REVER RETIRAR PRODUTO
                    // dados.current.info.documento.linhas = x

                    //if(getTokenInfo() !== undefined && getTokenInfo() !== null && parseInt(getTokenInfo().id) === parseInt('141')) {
                    //    Swal.fire('', JSON.stringify(dados.current.info), 'info')
            
                    //    return
                    //}
                    //else {


                        // await mutex.runExclusive(async () => {

                            await reqPOST(`${Hosts.SIMCore_API}/store/order`, dados.current)
                                // .then(() => { 
            
                                    if(dados.current.info['metodo_de_pagamento'] === '-1') {
                                        await get_payment_data('mbw', '')
                                    }
                                    else if(dados.current.info['metodo_de_pagamento'] === '0') {
                                        await get_payment_data('cc', '')
                                    }
                                    else if(dados.current.info['metodo_de_pagamento'] === '1') {
                                        await get_payment_data('mb', '')
                                    }
            
                                    // LINPAR CARRINHO
                                    // my_local_storage.removeItem('snowCart')
            
                                    // Swal.fire(language[lang].confirm_purchase_success, '', 'info')
                                    //props.history.push(endpoints.list) 
                                // })
                                // .catch(() => Swal.fire(language[lang].confirm_purchase_error, '', 'info'))
            
                        // })
                    //}
                }
            })

        }

        
    }

    const get_payment_data = async (type, mbw_number) => {

        /*
        const payload = {
            type: ["sale"],
            method: type,
            value: parseFloat(dados.current.info.documento.total_documento),
            currency: "EUR",
            expiration_time: "2022-10-04", 
            capture: {
                transaction_key: dados.current.info.id_usado_para_gerar_pagamento,
                descriptive: "Turistrela",
                capture_date: dados.current.info.data,
            },
            customer: {
                name: '',
                email: dados.current.info.documento.user_info.contacts[0].email,
                phone: mbway_mobile_phone,
                phone_indicative: '+351',
                fiscal_number: dados.current.info.documento.user_info.vat,
                key: dados.current.info.id_usado_para_gerar_pagamento,
                language: 'PT',
            }
        } 
        */

        // let mb_e = ''
        // let mb_r = ''
        // let data_limite = ''

        let body = { 
            key: dados.current.info.id_usado_para_gerar_pagamento, 
            value: parseFloat(dados.current.info.documento.total_documento), 
            method: type, 
            phone: mbw_number 
        }

        await reqPOST(`${Hosts.SIMCore_API}/store/payment/checkout/`, body)
            .then(async (res) => { 

                console.log('res', res)

                if(type === 'mb') {
                    // mb_e = res.data.response.method.entity
                    // mb_r = res.data.response.method.reference

                    // let someDate = new Date();
                    // let numberOfDaysToAdd = 2;
                    // let result = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
                    // let data_limite_aux = new Date(result);
                    // let data_aux = String(data_limite_aux.getDate()).padStart(2, '0') + '-' + String(data_limite_aux.getMonth() + 1).padStart(2, '0') + '-' + data_limite_aux.getFullYear() + ' ' + String(data_limite_aux.getHours()).padStart(2, '0') + ':' + String(data_limite_aux.getMinutes()).padStart(2, '0')
                    // data_limite = data_aux;
                }
                else if(type === 'mbw') {
                    // let someDate = new Date();
                    // let minutes = 30;
                    // let data_limite_aux = new Date(someDate.getTime() + minutes*60000);
                    // let data_aux = String(data_limite_aux.getDate()).padStart(2, '0') + '-' + String(data_limite_aux.getMonth() + 1).padStart(2, '0') + '-' + data_limite_aux.getFullYear() + ' ' + String(data_limite_aux.getHours()).padStart(2, '0') + ':' + String(data_limite_aux.getMinutes()).padStart(2, '0')
                    // data_limite = data_aux;
                }

                await reqPOST(`${Hosts.SIMCore_API}/store/purchase/payment`, {id: '', info: {id: '' + dados.current.info.id_usado_para_gerar_pagamento + '', companycode: '' + dados.current.info.companycode + '', obj: res.data} })
                    .then(async () => { 

                        console.log('DONE!!!')

                        await reqGET(`${Hosts.SIMCore_API}/store/order/payment/${Hosts.companycode}/${dados.current.info.id_usado_para_gerar_pagamento}`)
                            .then(async () => { 

                                if(type === 'cc') {
                                    // LINK para pagamento

                                    console.log('CC', dados.current.info['metodo_de_pagamento'])
                                    console.log('CC', res.data.response.method.url)

                                    set_cart({})
                                    set_cart_final([])
                                    my_local_storage.removeItem('snowCart')

                                    // compose_email(res_2.data[0].id, type, '', '', '')
                                    window.open(res.data.response.method.url, '_self')

                                }
                                else if(type === 'mbw') {
                                    // Mensagem de sucesso
                                    console.log('MBW', dados.current.info['metodo_de_pagamento'])
                                    // compose_email(res_2.data[0].id, type, '', '', data_limite)

                                    set_cart({})
                                    set_cart_final([])
                                    my_local_storage.removeItem('snowCart')

                                    // Swal.fire('', language[lang].encomenda_finalizada, 'info')

                                    Swal.fire({
                                        icon: 'info',
                                        html: language[lang].encomenda_finalizada,
                                        showConfirmButton: false
                                    })

                                    setTimeout(() => {
                                        // console.log('Hello, World!')

                                        window.open(Hosts.WEB_SITE_URI, '_self')
                                    }, 3000);
                                }
                                else if(type === 'mb') {
                                    // Mensagem de sucesso

                                    console.log('MB', dados.current.info['metodo_de_pagamento'])
                                    // compose_email(res_2.data[0].id, type, mb_e, mb_r, data_limite)

                                    await reqGET(`${Hosts.SIMCore_API}/store/order/payment/${Hosts.companycode}/${dados.current.info.id_usado_para_gerar_pagamento}`)
                                        .then(async (res_order) => { 

                                            res_order.data[0].info.documento.rmb.rmb_ref = res.data.response.method.reference
                                            res_order.data[0].info.documento.rmb.rmb_ent = res.data.response.method.entity
                                            res_order.data[0].info.documento.rmb.rmb_pag = parseFloat(body.value).toFixed(2).toString().replace('.', ',')

                                            await reqPUT(`${Hosts.SIMCore_API}/store/order`, res_order.data[0] )
                                                .then(_ => {} )
                                                .catch(_ => {} ) 

                                            set_cart({})
                                            set_cart_final([])
                                            my_local_storage.removeItem('snowCart')
        
                                            // Swal.fire('', language[lang].encomenda_finalizada, 'info')
        
                                            Swal.fire({
                                                icon: 'info',
                                                html: language[lang].encomenda_finalizada,
                                                showConfirmButton: false
                                            })
        
                                            setTimeout(() => {
                                                // console.log('Hello, World!')
        
                                                window.open(Hosts.WEB_SITE_URI, '_self')
                                            }, 3000);

                                        })
                                        .catch(erro => console.log(erro)) 

                                    // /store/order/payment/:companycode/:id


                                    

                                    
                                } 

                            })
                            .catch(erro => console.log(erro)) 


                    })
                    .catch(() => {

                        console.log('error')
        
                    })

            })
            .catch(() => {

                console.log('error')

            })

    }

    const removeCartFinal = async (e, k) => {

        // let l_aux = linhas_aux.filter(el => el.id_produto !== e.id)
        // let l_aux = []
        // linhas_aux.forEach(el => {
            // if(el.id_produto !== e.id)
            //    l_aux.push(el)
        // })
        // Swal.fire('', l_aux.length, 'info')

        let cart_f_aux = []
        if(cart.forfaits !== undefined && cart.forfaits.length > 0)
            cart_f_aux = cart.forfaits.filter(el => el.id !== e.id)
        
        let cart_e_aux = []
        if(cart.equipments !== undefined && cart.equipments.length > 0)
            cart_e_aux = cart.equipments.filter(el => el.id !== e.id)
        
        let cart_a_aux = []
        if(cart.atividades !== undefined && cart.atividades.length > 0)
            cart_a_aux = cart.atividades.filter(el => el.id !== e.id)
        
        let cart_aulas_aux = []
        if(cart.aulas !== undefined && cart.aulas.length > 0)
            cart_aulas_aux = cart.aulas.filter(el => el.id !== e.id)
        
        let cart_p_aux = []
        if(cart.packs !== undefined && cart.packs.length > 0)
            cart_p_aux = cart.packs.filter(el => el.id !== e.id)
        
        let cart_v_aux = []
        if(cart.vouchers !== undefined && cart.vouchers.length > 0)
            cart_v_aux = cart.vouchers.filter(el => el.id !== e.id)

        cart.forfaits = cart_f_aux
        cart.equipments = cart_e_aux
        cart.atividades = cart_a_aux
        cart.aulas = cart_aulas_aux
        cart.packs = cart_p_aux
        cart.vouchers = cart_v_aux

        // set_linhas_aux(l_aux)
        // set_dados(dados.current.info.documento.linhas = l_aux)
        upgrade_cart('snowCart', JSON.stringify(cart))
        setRefresh(refresh + 1)


        return
    }

    return isRedirect ?
        <Redirect  to="/" />
    :
        isloading ?

            <Loader />

        :
            <>
                <Navbar /> 

                <Breadcrumb /> 

                <div id="cart" className="container-fluid my-5">
                    <div className="row">
                        <div className="container">

                            <div className='row'>
                                <div className='col-12 col-lg-10'>

                                    <div>
                                        <ul className="timeline d-none">
                                            <li className="completed">
                                                <div className="timestamp">
                                                    <span className="author"></span>
                                                    <span className="date"></span>
                                                </div>
                                                <div className="status">
                                                    <p>Order</p>
                                                </div>
                                            </li>

                                            <li className={step === '1' || step === '2' ? "completed" : ""}>
                                                <div className="timestamp">
                                                    <span className="author"></span>
                                                    <span className="date"></span>
                                                </div>
                                                <div className="status">
                                                    <p>Personal Data</p>
                                                </div>
                                            </li>

                                            <li className={step === '2' ? "completed" : ""}>
                                                <div className="timestamp">
                                                    <span className="author"></span>
                                                    <span className="date"></span>
                                                </div>
                                                <div className="status">
                                                    <p>Payment</p>
                                                </div>
                                            </li>
                                            
                                        </ul>

                                        <div className="timeline">
                                            <div className='step'>
                                                <span className={"dot mr-3" + (step === '0' ? " active" : " completed")}>
                                                    {
                                                    step === '0' ?
                                                        <span>1</span>
                                                    :
                                                        <i className="bi bi-check-lg"></i>
                                                    }
                                                </span>
                                                <span className={"label" + (step === '0' ? " active" : " completed")}>{language[lang].encomenda}</span>
                                                <span className={"line mx-3" + (step === '0' ? " active" : " completed")}></span>
                                            </div>

                                            <div className='step'>
                                                <span className={"dot mr-3" + (step === '1' ? " active" : (step === '2' ? " completed" : ""))}>
                                                    {
                                                    step === '0' || step === '1' ?
                                                        <span>2</span>
                                                    :
                                                        <i className="bi bi-check-lg"></i>
                                                    }
                                                </span>
                                                <span className={"label" + (step === '1' ? " active" : (step === '2' ? " completed" : ""))}>{language[lang].dados_pessoais}</span>
                                                <span className={"line mx-3" + (step === '1' ? " active" : (step === '2' ? " completed" : ""))}></span>
                                            </div>
                                            <div className='step'>
                                                
                                                <span className={"dot mr-3" + (step === '2' ? " active" : "")}>
                                                    <span>3</span>
                                                </span>
                                                <span className={"label" + (step === '2' ? " active" : "")}>{language[lang].pagamento}</span>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="tab-content">
                                        <div className={step === '0' ? "tab-pane show active" : 'tab-pane'} role="tabpanel">
                                            <div className="row mb-5">
                                                <div className="col-12">
                                                    <h1 className="vertical-title-right">{language[lang].encomendar}</h1>
                                                </div>

                                                <div className="col-12">

                                                    <div className='client-order'>
                                                        <div className="card card-flush py-4 flex-row-fluid overflow-hidden border-0">
                                                            <div className="card-body pt-0">
                                                                <div className="my-3">
                                                                    <h3 className="titulo-primario">{language[lang].my_order}</h3>
                                                                </div>

                                                                <div className="table-responsive">
                                                                    <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">

                                                                        <thead>
                                                                            <tr className="titulo-secundario p-4 w-100 bg-color-gray-light">
                                                                                <th className="border-0">{language[lang].product}</th>
                                                                                <th className="text-right border-0">{language[lang].unit_price}</th>
                                                                                <th className="text-center border-0">{language[lang].qtd}</th>
                                                                                <th className="text-right border-0">{language[lang].vat}</th>
                                                                                <th className="text-right border-0">{language[lang].total}</th>
                                                                                <th className="text-right border-0"></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody className="fw-bold text-gray-600">
                                                                            {
                                                                            cart_final !== undefined ?
                                                                                cart_final.map((el, key) => {
                                                                                    return (
                                                                                        <tr key={'c_' + key}>
                                                                                            <td>
                                                                                                <div className="d-flex align-items-center">
                                                                                                    <div className="ms-5">
                                                                                                        <span className="fw-bolder text-gray-600 text-hover-primary">
                                                                                                            {el.name} 
                                                                                                            {
                                                                                                            el.category !== '' ?
                                                                                                                <>
                                                                                                                    <span> | <span className=''> {el.category}</span></span>
                                                                                                                </>
                                                                                                            :
                                                                                                                ''
                                                                                                            }
                                                                                                            {
                                                                                                            el.observation !== '' ?
                                                                                                                <>
                                                                                                                    <br /> 
                                                                                                                    <small>{el.observation}</small>
                                                                                                                </>
                                                                                                            :
                                                                                                                ''
                                                                                                            }
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td className="text-right">
                                                                                                {/*parseFloat(el.unit_price).toFixed(3)*/}
                                                                                                {parseFloat(el.unit_price_iva).toFixed(2)}€
                                                                                            </td>
                                                                                            <td className="text-center">
                                                                                                {el.quantity}
                                                                                            </td>
                                                                                            <td className="text-right">
                                                                                                {parseFloat(el.vat).toFixed(0)}%
                                                                                            </td>
                                                                                            <td className="text-right">
                                                                                                {parseFloat(el.total).toFixed(2)}€
                                                                                            </td>
                                                                                            <td className="text-right d-none">
                                                                                                <button className={el.name !== 'Aluguer da Pista' ? "btn btn-primary px-4" : 'd-none'} onClick={() => removeCartFinal(el, key)}>x</button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                })
                                                                            :
                                                                                null
                                                                            }
                                                                            <tr className='d-none'>
                                                                                <td colSpan="4" className="text-right">{language[lang].subtotal}</td>
                                                                                <td className="text-right">{parseFloat(subtotal).toFixed(2)}€</td>
                                                                            </tr>
                                                                            {
                                                                                total_vat.map((el_total_vat, key_total_vat) => {
                                                                                    return (
                                                                                        <tr key={key_total_vat} className="d-none">
                                                                                            <td colSpan="4" className="text-right">{language[lang].vat} ({el_total_vat.id}%)</td>
                                                                                            <td className="text-right">{parseFloat(el_total_vat.value).toFixed(2)}€</td>
                                                                                        </tr>
                                                                                    )

                                                                                })
                                                                            }
                                                                            <tr>
                                                                                <td colSpan="4" className="fs-3 text-dark text-right">{language[lang].total}</td>
                                                                                <td className="text-dark fs-3 fw-boldest text-right">{parseFloat(total).toFixed(2)}€</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>

                                                                <div className='col-12 text-right mt-3'>
                                                                    <a href={Hosts.WEB_SITE_URI + "store/tarifas"} className="btn btn-secondary px-4 mr-3"><i className="bi bi-arrow-left mr-2"></i> {language[lang].prev}</a>
                                                                    <button className="btn btn-secondary px-4 mr-3 d-none" onClick={() => set_step('0')}><i className="bi bi-arrow-left mr-2"></i> {language[lang].prev}</button>
                                                                    <button className="btn btn-primary px-4"  onClick={() => {(getTokenInfo() !== undefined && getTokenInfo() !== null && getTokenInfo().profile === 'Cliente') && agree ? set_step('1') : validate_step('1') }}>{language[lang].next} <i className="bi bi-arrow-right ml-2"></i> </button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className={step === '1' ? "tab-pane show active" : 'tab-pane'} role="tabpanel">
                                            <div className="row mb-5">
                                                <div className="col-12">
                                                    <h1 className="vertical-title-right">Encomendar</h1>
                                                </div>

                                                <div className="col-12">

                                                    <div className="client-info">
                                                        <div className="card card-flush py-4 border-0">
                                                            <div className="card-body pt-0">

                                                                <div>
                                                                    <div className="my-3">
                                                                        <h3 className="titulo-primario">{language[lang].dados_faturacao}</h3>
                                                                    </div>
                                                                    <div className="row mb-3">
                                                                        <div className="col-8">
                                                                            <label>{language[lang].dados_nome} (*)</label>
                                                                            <input type="text" name="name" className="form-control" placeholder="" value={dados.current.info.documento.user_info.name} onChange={handle_change_info_documento_userinfo} />
                                                                        </div>

                                                                        <div className="col-4">
                                                                            <label>{language[lang].dados_vat} (*)</label>
                                                                            <input type="text" name="vat" className="form-control" placeholder="" value={dados.current.info.documento.user_info.vat} onChange={handle_change_info_documento_userinfo} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mb-3">
                                                                        <div className="col-8">
                                                                            <label>{language[lang].dados_email} (*)</label>
                                                                            <input type="email" name="email" className="form-control" placeholder="" value={dados.current.info.documento.user_info.contacts[0].email} onChange={handle_change_info_documento_userinfo_contacts} />
                                                                        </div>

                                                                        <div className="col-4">
                                                                            <label>{language[lang].dados_mobile_phone}</label>
                                                                            <input type="text" name="mobile_phone" className="form-control" placeholder="" value={dados.current.info.documento.user_info.contacts[0].mobile_phone} onChange={handle_change_info_documento_userinfo_contacts} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mb-3">
                                                                        <div className="col-8">
                                                                            <label>{language[lang].dados_morada}</label>
                                                                            <textarea type="text" name="address_detail" className="form-control" placeholder="" value={dados.current.info.documento.user_info.addresses[0].address_detail} onChange={handle_change_info_documento_userinfo_addresses} />
                                                                        </div>

                                                                        <div className="col-4">
                                                                            <label className="form-label">{language[lang].dados_localidade}</label>
                                                                            <input type="text" name="localidade" className="form-control" placeholder="" value={dados.current.info.documento.user_info.addresses[0].localidade} onChange={handle_change_info_documento_userinfo_addresses} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mb-3">
                                                                        <div className="col-6">
                                                                            <label>{language[lang].dados_codigo_postal}</label>
                                                                            <input type="text" name="postal_code" className="form-control" placeholder="" value={dados.current.info.documento.user_info.addresses[0].postal_code} onChange={handle_change_info_documento_userinfo_addresses} />
                                                                        </div>
                                                                        <div className="col-6">
                                                                        <label>{language[lang].dados_pais}</label>
                                                                            <input type="text" name="country" className="form-control" placeholder="" value={dados.current.info.documento.user_info.addresses[0].country} onChange={handle_change_info_documento_userinfo_addresses} />
                                                                        </div>
                                                                    </div>

                                                                    <div className="row mb-3">
                                                                        <div className="col-12">
                                                                            <input type="checkbox" id="agree" onChange={checkboxHandler} defaultChecked={checkboxHandler_reload(agree)}/>
                                                                            <label className="pl-2" htmlFor="agree">{language[lang].agree_terms_conditions}</label> <a href={"https://cdn.assec.app/repo/turistrela/estanciaski/upload/content/termos-condicoes-estancia-ski-serra-da-estrela-" + lang + ".pdf"} target="_blank" rel="noreferrer">{language[lang].terms_conditions_link}</a>

                                                                            <label className="pl-2" htmlFor="agree">{language[lang].agree_return_policy}</label> <a href={"https://cdn.assec.app/repo/turistrela/estanciaski/upload/content/politica-de-devolucao-e-reembolso-" + lang + ".pdf"} target="_blank" rel="noreferrer">{language[lang].return_policy_link}</a>

                                                                            <label className="pl-2 small" htmlFor="agree">{language[lang].terms_conditions_required}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className='col-12 text-right mt-3'>
                                                                    <button className="btn btn-secondary px-4 mr-3" onClick={() => set_step('0')}><i className="bi bi-arrow-left mr-2"></i> {language[lang].prev}</button>
                                                                    <button className="btn btn-primary px-4" onClick={() => validate_step('2') }>{language[lang].next} <i className="bi bi-arrow-right ml-2"></i> </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className={step === '2' ? "tab-pane show active" : 'tab-pane'} role="tabpanel">
                                            <div className="row mb-5">

                                                <div className="col-12">
                                                    <h1 className="vertical-title-right">Encomendar</h1>
                                                </div>

                                                <div className="col-12">


                                                    <div className="card card-flush py-4 border-0">
                                                        <div className="card-body pt-0">

                                                            <div>

                                                                <div className="my-3">
                                                                    <h3 className="titulo-primario">{language[lang].dados_tipo_pagamento}</h3>
                                                                </div>

                                                                <div className="row mb-3">

                                                                    <div className='col-6 col-sm-4'> 
                                                                        <button type='button' className='btn btn-secondary' onClick={() => set_paymet('cc', '0')}><img className='img-fluid' src={repo_site_assets_link("cc-square.png")} alt="cc" /></button>
                                                                    </div>
                                                                    <div className='col-6 col-sm-4'> 
                                                                        <button type='button' className='btn btn-secondary' onClick={() => set_paymet('mbw', '-1')}><img className='img-fluid' src={repo_site_assets_link("mbway-square.png")} alt="mbw" /></button>
                                                                    </div>
                                                                    <div className='col-6 col-sm-4'> 
                                                                        <button type='button' className='btn btn-secondary' onClick={() => set_paymet('mb', '1')}><img className='img-fluid' src={repo_site_assets_link("mb-square.png")} alt="rm" /></button>
                                                                    </div>

                                                                </div>

                                                                <div className='col-12 text-right mt-3'>
                                                                    <button className="btn btn-secondary px-4" onClick={() => set_step('1')}><i className="bi bi-arrow-left mr-2"></i> {language[lang].prev}</button>
                                                                    <button className="btn btn-primary px-4 d-none" onClick={handleSubmit}>{language[lang].confirm_purchase_confirm} <i className="bi bi-arrow-right ml-2"></i> </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div> 

                    </div> 
                </div>    
            
                <Footer />
            </>

};
export default Cart;

